
export function mapMonthSeasonToString(monthSeason) {
    if (monthSeason.tag === 1) {
        return "season";
    }
    else {
        return "month";
    }
}

