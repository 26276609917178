import { FSharpRef, Record, Union } from "../fable-library-js.4.24.0/Types.js";
import { record_type, list_type, int32_type, union_type, string_type } from "../fable-library-js.4.24.0/Reflection.js";
import { tail as tail_1, head as head_1, isEmpty, cons, empty } from "../fable-library-js.4.24.0/List.js";
import { tryParse, newGuid, toString } from "../fable-library-js.4.24.0/Guid.js";
import { FSharpResult$2 } from "../fable-library-js.4.24.0/Result.js";
import { some, value } from "../fable-library-js.4.24.0/Option.js";

export class PerformCommandResult$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CommandProcessed", "CommandNotProcessed"];
    }
}

export function PerformCommandResult$1_$reflection(gen0) {
    return union_type("pogona_domain.Domain.PerformCommandResult`1", [gen0], PerformCommandResult$1, () => [[["result", gen0]], [["reason", string_type]]]);
}

export class AggregateVersion extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Version", "NewAggregate"];
    }
}

export function AggregateVersion_$reflection() {
    return union_type("pogona_domain.Domain.AggregateVersion", [], AggregateVersion, () => [[["version", int32_type]], []]);
}

export class Aggregate$1 extends Record {
    constructor(Id, Version, Changes, ExpectedVersion, AggregateType) {
        super();
        this.Id = Id;
        this.Version = Version;
        this.Changes = Changes;
        this.ExpectedVersion = ExpectedVersion;
        this.AggregateType = AggregateType;
    }
}

export function Aggregate$1_$reflection(gen0) {
    return record_type("pogona_domain.Domain.Aggregate`1", [gen0], Aggregate$1, () => [["Id", string_type], ["Version", AggregateVersion_$reflection()], ["Changes", list_type(gen0)], ["ExpectedVersion", AggregateVersion_$reflection()], ["AggregateType", string_type]]);
}

export function setAggregateId(id, aggregate) {
    return new Aggregate$1(id, aggregate.Version, aggregate.Changes, aggregate.ExpectedVersion, aggregate.AggregateType);
}

export function incrementVersion(version) {
    if (version.tag === 0) {
        return new AggregateVersion(0, [version.fields[0] + 1]);
    }
    else {
        return new AggregateVersion(0, [1]);
    }
}

export function setExpectedVersionToVersion(aggregate) {
    return new Aggregate$1(aggregate.Id, aggregate.Version, aggregate.Changes, aggregate.Version, aggregate.AggregateType);
}

export function clearChanges(aggregate) {
    return new Aggregate$1(aggregate.Id, aggregate.Version, empty(), aggregate.ExpectedVersion, aggregate.AggregateType);
}

export function incrementAggregateVersions(aggregate) {
    return setExpectedVersionToVersion(new Aggregate$1(aggregate.Id, incrementVersion(aggregate.Version), aggregate.Changes, aggregate.ExpectedVersion, aggregate.AggregateType));
}

export function addChange(change, aggregate) {
    return new Aggregate$1(aggregate.Id, incrementVersion(aggregate.Version), cons(change, aggregate.Changes), aggregate.ExpectedVersion, aggregate.AggregateType);
}

export function generateAggregateId() {
    return toString(newGuid(), "N");
}

export function initAggregateWithId(aggregateType, aggregateId) {
    return new Aggregate$1(aggregateId, new AggregateVersion(1, []), empty(), new AggregateVersion(1, []), aggregateType);
}

export function initAggregate(aggregateTypeId) {
    return initAggregateWithId(aggregateTypeId, generateAggregateId());
}

export function validateAggregateId(id) {
    let matchValue;
    let outArg = "00000000-0000-0000-0000-000000000000";
    matchValue = [tryParse(id, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return new FSharpResult$2(0, [matchValue[1]]);
    }
    else {
        return new FSharpResult$2(1, [undefined]);
    }
}

export function foldCommandsUntilError(perform_mut, aggregate_mut, commands_mut) {
    foldCommandsUntilError:
    while (true) {
        const perform = perform_mut, aggregate = aggregate_mut, commands = commands_mut;
        if (isEmpty(commands)) {
            if (aggregate != null) {
                return new PerformCommandResult$1(0, [new FSharpResult$2(0, [value(aggregate)])]);
            }
            else {
                return new PerformCommandResult$1(1, ["Aggregate not built by commands"]);
            }
        }
        else {
            const _arg = perform(head_1(commands), aggregate);
            if (_arg.tag === 1) {
                return new PerformCommandResult$1(1, [_arg.fields[0]]);
            }
            else {
                const result = _arg.fields[0];
                if (result.tag === 1) {
                    return new PerformCommandResult$1(0, [new FSharpResult$2(1, [result.fields[0]])]);
                }
                else {
                    perform_mut = perform;
                    aggregate_mut = some(result.fields[0]);
                    commands_mut = tail_1(commands);
                    continue foldCommandsUntilError;
                }
            }
        }
        break;
    }
}

