import {
  StoreSearchResult,
  StateCountyResult,
  DistrictResult,
  MarketResult,
  StoreStatusResult,
  ServiceResult,
  DeliveryMethodResult,
  TimeZoneResult,
  PriceZoneResult,
  DmaResult,
  DistributionCenterResult,
  StoreCreateUpdatePayload,
  StoreCopy,
  Address,
  GroomingStoreService,
  DogWashService,
  CurbsidePickupStoreService,
  DeliveryStoreService,
  AutoShipStoreService,
  StoreListingAllResult,
  StoreListingAllSearchParam,
  StoreListingAllFields,
  SocialMedia,
  OnlineGroomingStoreService,
} from '@psp/pogona_pcl_client_js';
import { PclState, StoreService, StoreListingAllHeaders, StoreListingAllSearch } from './state';
import * as pcldto from '@psp/pcldto';
import ODataProps from '@/utils/ODataProps';

export const setClient = 'setClient';

export const setStoreData = 'setStoreData';
export const setStoreListingAllData = 'setStoreListingAllData';
export const setStoreListingAllParams = 'setStoreListingAllParams';
export const setStoreListingAllOrderBy = 'setStoreListingAllOrderBy';
export const incrementStoreListingAllSkip = 'incrementStoreListingAllSkip';
export const setStoreListingAllSkip = 'setStoreListingAllSkip';

export const clearStoreData = 'clearStoreData';

export const incrementStoreSearchPage = 'incrementStoreSearchPage';
export const setStoreSearchGenericText = 'setStoreSearchGenericText';

export const clearStoreSearch = 'clearStoreSearch';
export const setStoreSearchFirstPage = 'setStoreSearchFirstPage';
export const setStoreSearchOrderBy = 'setStoreSearchOrderBy';
export const setStoreSearchOnlyOpenStores = 'setStoreSearchOnlyOpenStores';

export const setCountyData = 'setCountyData';
export const setDistrictData = 'setDistrictData';
export const setMarketData = 'setMarketData';
export const setStateData = 'setStateData';
export const setStoreStatusesData = 'setStoreStatusesData';
export const setServicesData = 'setServicesData';
export const setTimeZoneData = 'setTimeZoneData';
export const setPriceZoneData = 'setPriceZoneData';
export const setDmaData = 'setDmaData';
export const setDistributionCenterDataData = 'setDistributionCenterDataData';
export const setDogWashTubTypesData = 'setDogWashTubTypesData';
export const setDivisionRegionsData = 'setDivisionRegionsData';
export const setDeliveryMethodData = 'setDeliveryMethodData';
export const setStoreEditData = 'setStoreEditData';
export const setStoreEditDataStoreNumber = 'setStoreEditDataStoreNumber';
export const setOwnersData = 'setOwnersData';
export const setOwnersDataCount = 'setOwnersDataCount';
export const setOwnersDataSearchText = 'setOwnersDataSearchText';
export const initStoreEditData = 'initStoreEditData';

export const setStoreSearchInProgress = 'setStoreSearchInProgress';
export const setCountySearchInProgress = 'setCountySearchInProgress';
export const setDistrictGetInProgress = 'setDistrictGetInProgress';
export const setMarketGetInProgress = 'setMarketGetInProgress';
export const setStoreStatusesGetInProgress = 'setStoreStatusesGetInProgress';
export const setStateGetInProgress = 'setStateGetInProgress';
export const setServicesGetInProgress = 'setStateGetInProgress';
export const setDeliveryMethodsGetInProgress = 'setDeliveryMethodsGetInProgress';
export const setCreateUpdateStoreInProgress = 'setCreateUpdateStoreInProgress';
export const setTimeZoneGetInProgress = 'setTimeZoneGetInProgress';
export const setPriceZoneGetInProgress = 'setPriceZoneGetInProgress';
export const setDmasGetInProgress = 'setDmasGetInProgress';
export const setDistributionCenterGetInProgress = 'setDistributionCenterGetInProgress';
export const setStoreListingAllDataGetInProgress = 'setStoreListingAllDataGetInProgress';
export const setDivisionRegionsGetInProgress = 'setDivisionRegionsGetInProgress';
export const setOwnersGetInProgress = 'setOwnersGetInProgress';
export const setOwnersSaveInProgress = 'setOwnersSaveInProgress';
export const setOwnersDeleteInProgress = 'setOwnersDeleteInProgress';
export const setExportListingAllInProgress = 'setExportListingAllInProgress';
export const setDeliveryAreaLookupGetInProgress = 'setDeliveryAreaLookupGetInProgress';
export const setExportOwnersInProgress = 'setExportOwnersInProgress';
export const setSocialMediaGetInProgress = 'setSocialMediaGetInProgress';
export const setDogWashTubTypesGetInProgress = 'setDogWashTubTypesGetInProgress';

export const setCreateUpdateStorePayload = 'createUpdateStorePayload';
export const setDivisionRegions = 'setDivisionRegions';
export const setRoleNames = 'setRoleNames';
export const setEmployees = 'setEmployees';
export const clearDivisionRegionEdit = 'clearDivisionRegionEdit';
export const setDivisionRegionEdit = 'setDivisionRegionEdit';

export const removePclListingAllHeader = 'removePclListingAllHeader';
export const addPclListingAllHeader = 'addPclListingAllHeader';
export const resetPclListingAllHeader = 'resetPclListingAllHeader';
export const resetStoreListingAllParams = 'resetStoreListingAllParams';
export const resetStoreListingAllFields = 'resetStoreListingAllFields';
export const setPclListingAllLoaded = 'setPclListingAllLoaded';
export const setPclListingAllOptions = 'setPclListingAllOptions';
export const setDivisionRegionsNoRelated = 'setDivisionRegionsNoRelated';
export const setOwnersDataOData = 'setOwnersDataOData';
export const setDivisionRegionResults = 'setDivisionRegionResults';
export const setDistrictResults = 'setDistrictResults';
export const setMarketResults = 'setMarketResults';
export const setDeliveryAreaLookup = 'setDeliveryAreaLookup';
export const setSocialMediaLookup = 'setSocialMediaLookup';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  [setClient](state: PclState, payload: any): void {
    state.init(payload.http, payload.baseUrl);
  },
  [setStoreData](state: PclState, payload: StoreSearchResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new StoreSearchResult(), payload[i]);
    }
    if (state.storeSearch.PageNumber === 1) {
      state.storeData = payload;
    } else {
      state.storeData.push(...payload);
    }
  },
  [setStoreListingAllData](state: PclState, payload: StoreListingAllResult): void {
    if ((state?.storeListingAllParams?.skip ?? 0) === 0) {
      state.storeListingAllData = payload;
    } else {
      state.storeListingAllData.Results.push(...payload.Results);
    }
  },
  [setStoreListingAllParams](state: PclState, payload: StoreListingAllSearchParam[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(
        new StoreListingAllSearchParam(
          payload[i].Field,
          payload[i].SearchValue,
          payload[i].SearchType,
        ),
        payload[i],
      );
    }

    if (state.storeListingAllParams) {
      if (state.storeListingAllParams.skip === 0) {
        state.storeListingAllParams.searchParams = payload;
      } else {
        state.storeListingAllParams.searchParams.push(...payload);
      }
    }
  },
  [setStoreListingAllOrderBy](state: PclState, payload: Array<string>): void {
    if (state.storeListingAllParams) {
      state.storeListingAllParams.orderBy = payload;
    }
  },

  [incrementStoreListingAllSkip](state: PclState, payload: number): void {
    if (state.storeListingAllParams) {
      state.storeListingAllParams.skip += payload;
    }
  },
  [setStoreListingAllSkip](state: PclState, payload: number): void {
    if (state.storeListingAllParams) {
      state.storeListingAllParams.skip = payload;
    }
  },
  [resetStoreListingAllParams](state: PclState): void {
    state.storeListingAllParams = new StoreListingAllSearch();
    state.storeListingAllParams.top = 50;
    state.storeListingAllParams.skip = 0;
  },
  [resetStoreListingAllFields](state: PclState): void {
    state.storeListingAllFields = new StoreListingAllFields();
  },
  [setStateData](state: PclState, payload: string[]): void {
    state.stateData = payload;
  },
  [setStoreStatusesData](state: PclState, payload: StoreStatusResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new StoreStatusResult(), payload[i]);
    }

    state.storeStatusIdTypesData = payload;
  },

  [incrementStoreSearchPage](state: PclState, payload: number): void {
    state.storeSearch.PageNumber += payload;
  },
  [setStoreSearchInProgress](state: PclState, payload: boolean): void {
    state.storeSearchInProgress = payload;
  },
  [setCountySearchInProgress](state: PclState, payload: boolean): void {
    state.countySearchInProgress = payload;
  },
  [setDistrictGetInProgress](state: PclState, payload: boolean): void {
    state.districtGetInProgress = payload;
  },
  [setMarketGetInProgress](state: PclState, payload: boolean): void {
    state.marketGetInProgress = payload;
  },
  [setStateGetInProgress](state: PclState, payload: boolean): void {
    state.stateGetInProgress = payload;
  },
  [setStoreStatusesGetInProgress](state: PclState, payload: boolean): void {
    state.storeStatusesGetInProgress = payload;
  },
  [setServicesGetInProgress](state: PclState, payload: boolean): void {
    state.servicesGetInProgress = payload;
  },
  [setDeliveryMethodsGetInProgress](state: PclState, payload: boolean): void {
    state.deliveryMethodsGetInProgress = payload;
  },
  [setCreateUpdateStoreInProgress](state: PclState, payload: boolean): void {
    state.createUpdateStoreInProgress = payload;
  },
  [setTimeZoneGetInProgress](state: PclState, payload: boolean): void {
    state.timeZoneGetInProgress = payload;
  },
  [setPriceZoneGetInProgress](state: PclState, payload: boolean): void {
    state.priceZoneGetInProgress = payload;
  },
  [setDmasGetInProgress](state: PclState, payload: boolean): void {
    state.dmaGetInProgress = payload;
  },
  [setDistributionCenterGetInProgress](state: PclState, payload: boolean): void {
    state.distributionCenterGetInProgress = payload;
  },
  [setStoreListingAllDataGetInProgress](state: PclState, payload: boolean): void {
    state.storeListingAllDataGetInProgress = payload;
  },
  [setDivisionRegionsGetInProgress](state: PclState, payload: boolean): void {
    state.divisionRegionsGetInProgress = payload;
  },
  [setOwnersGetInProgress](state: PclState, payload: boolean): void {
    state.ownersGetInProgress = payload;
  },
  [setOwnersSaveInProgress](state: PclState, payload: boolean): void {
    state.ownersSaveInProgress = payload;
  },
  [setOwnersDeleteInProgress](state: PclState, payload: boolean): void {
    state.ownersDeleteInProgress = payload;
  },
  [setExportListingAllInProgress](state: PclState, payload: boolean): void {
    state.exportListingAllInProgress = payload;
  },
  [setDeliveryAreaLookupGetInProgress](state: PclState, payload: boolean): void {
    state.deliveryAreaLookupGetInProgress = payload;
  },
  [setExportOwnersInProgress](state: PclState, payload: boolean): void {
    state.exportOwnersInProgress = payload;
  },
  [setSocialMediaGetInProgress](state: PclState, payload: boolean): void {
    state.socialMediaGetInProgress = payload;
  },
  [setDogWashTubTypesGetInProgress](state: PclState, payload: boolean): void {
    state.dogWashTubTypesGetInProgress = payload;
  },
  [setCountyData](state: PclState, payload: StateCountyResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new StateCountyResult(), payload[i]);
    }
    state.countyData = payload;
  },
  [setDistrictData](state: PclState, payload: DistrictResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new DistrictResult(), payload[i]);
    }
    state.districtData = payload;
  },
  [setMarketData](state: PclState, payload: MarketResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new MarketResult(), payload[i]);
    }
    state.marketData = payload;
  },
  [setServicesData](state: PclState, payload: ServiceResult[]): void {
    const services = [] as StoreService[];
    for (let i = 0; i < payload.length; i++) {
      services.push(Object.assign(payload[i] as StoreService));
    }
    state.servicesData = services;
  },
  [setTimeZoneData](state: PclState, payload: TimeZoneResult[]): void {
    const timeZones = [] as TimeZoneResult[];
    for (let i = 0; i < payload.length; i++) {
      timeZones.push(Object.assign(payload[i] as TimeZoneResult));
    }
    state.timeZonesData = timeZones;
  },
  [setPriceZoneData](state: PclState, payload: PriceZoneResult[]): void {
    const priceZones = [] as PriceZoneResult[];
    for (let i = 0; i < payload.length; i++) {
      priceZones.push(Object.assign(payload[i] as PriceZoneResult));
    }
    state.priceZonesData = priceZones;
  },
  [setDmaData](state: PclState, payload: DmaResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new DmaResult(), payload[i]);
    }
    state.dmaData = payload;
  },
  [setDistributionCenterDataData](state: PclState, payload: DistributionCenterResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new DistributionCenterResult(), payload[i]);
    }
    state.distributionCenterData = payload;
  },
  [setDogWashTubTypesData](state: PclState, payload: pcldto.PclDogWashTubType[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(
        new pcldto.PclDogWashTubType(payload[i].DogWashTubTypeId, payload[i].Name),
        payload[i],
      );
    }
    state.dogWashTubTypes = payload;
  },
  [setDivisionRegionsData](state: PclState, payload: pcldto.pclDivisionRegion[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(
        new pcldto.pclDivisionRegion(payload[i].DivisionRegionId, payload[i].Name),
        payload[i],
      );
    }
    state.divisionRegionsData = payload;
  },
  [setDeliveryMethodData](state: PclState, payload: DeliveryMethodResult[]): void {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = Object.assign(new DeliveryMethodResult(), payload[i]);
    }
    state.deliveryMethodData = payload;
  },
  [setStoreEditData](state: PclState, payload: StoreCreateUpdatePayload): void {
    state.storeEditData = Object.assign(new StoreCreateUpdatePayload(), payload);
  },
  [setStoreEditDataStoreNumber](state: PclState, payload: string): void {
    state.storeEditData.Store.StoreNumber = payload;
  },
  [initStoreEditData](state: PclState, storeNumber: string | undefined): void {
    const payload = new StoreCreateUpdatePayload();
    payload.Store = new StoreSearchResult();
    payload.Store.Address = new Address();

    payload.GroomingStoreService = new GroomingStoreService();
    payload.DogWashService = new DogWashService();
    payload.CurbsidePickupStoreService = new CurbsidePickupStoreService();
    payload.DeliveryStoreService = new DeliveryStoreService();
    payload.AutoShipStoreService = new AutoShipStoreService();
    payload.OnlineGroomingStoreService = new OnlineGroomingStoreService();

    payload.CopyStore = new StoreCopy();

    if (storeNumber && storeNumber.length > 0) {
      payload.Store.StoreNumber = storeNumber;
    }

    state.storeEditData = payload;
  },
  [setStoreSearchGenericText](state: PclState, payload: string): void {
    state.storeSearch.GenericText = payload;
  },
  [setStoreSearchOnlyOpenStores](state: PclState, payload: boolean): void {
    state.storeSearch.OnlyOpenStores = payload;
  },
  [setStoreSearchOrderBy](state: PclState, payload: any): void {
    const sortBy = payload.sortBy;
    const sortDesc = payload.sortDesc;

    state.storeSearch.OrderBy = [];

    for (const ob in sortBy) {
      state.storeSearch.OrderBy.push(
        `${sortBy[ob]} ${sortDesc && sortDesc[ob] === true ? 'desc' : 'asc'}`,
      );
    }
  },
  [clearStoreData](state: PclState): void {
    state.storeData = [];
    state.storeSearch.PageNumber = 1;
  },
  [clearStoreSearch](state: PclState): void {
    state.storeSearch.GenericText = '';
    state.storeSearch.PageNumber = 1;
  },
  [setStoreSearchFirstPage](state: PclState): void {
    state.storeSearch.PageNumber = 1;
  },
  [setDivisionRegions](state: PclState, payload: pcldto.pclDivisionRegionWithRelated[]): void {
    state.divisionRegions = payload;
  },
  [setRoleNames](state: PclState, payload: pcldto.pclRoleName[]): void {
    state.roleNames = payload;
  },
  [setCreateUpdateStorePayload](state: PclState, payload: StoreCreateUpdatePayload): void {
    state.createUpdateStorePayload = payload;
  },
  [setEmployees](state: PclState, payload: pcldto.pclEmployee[]): void {
    state.employees = payload;
  },
  [clearDivisionRegionEdit](state: PclState): void {
    state.divisionRegionEdit = new pcldto.pclDivisionRegionWithRelated(null, [], 0, '', null);
  },
  [setDivisionRegionEdit](state: PclState, payload: pcldto.pclDivisionRegionWithRelated): void {
    state.divisionRegionEdit = payload;
  },
  [setPclListingAllLoaded](state: PclState, payload: boolean): void {
    state.pclListingAllLoaded = payload;
  },
  [setPclListingAllOptions](state: PclState, payload: any): void {
    state.pclListingAllOptions = payload;
  },
  [removePclListingAllHeader](state: PclState, payload: string): void {
    if (state.pclListingAllHeaders) {
      const colIx = state.pclListingAllHeaders?.headers.findIndex(x => x.value === payload);
      const removedIx = state.pclRemovedHeaders.findIndex(x => x.value === payload);

      if (colIx > -1 && removedIx === -1) {
        state.pclListingAllHeaders.headers[colIx].align = ' d-none';
        state.pclRemovedHeaders.push(state.pclListingAllHeaders.headers[colIx]);
      }
    }
  },
  [addPclListingAllHeader](state: PclState, payload: string): void {
    if (state.pclListingAllHeaders) {
      const colIx = state.pclListingAllHeaders?.headers.findIndex(x => x.value === payload);
      if (colIx > -1) {
        state.pclListingAllHeaders.headers[colIx].align = '';
      }

      const removedIx = state.pclRemovedHeaders.findIndex(x => x.value === payload);
      if (removedIx > -1) {
        state.pclRemovedHeaders.splice(removedIx, 1);
      }
    }
  },
  [resetPclListingAllHeader](state: PclState): void {
    state.pclListingAllHeaders = new StoreListingAllHeaders();

    // populate pclRemovedHeaders with the default hidden
    state.pclRemovedHeaders = [];
    for (const header of state.pclListingAllHeaders.headers) {
      if (header.align && header.align.trim() === 'd-none') {
        state.pclRemovedHeaders.push(header);
      }
    }
  },
  [setDivisionRegionsNoRelated](state: PclState, payload: pcldto.pclDivisionRegion[]): void {
    state.divisionRegionsNoRelated = payload;
  },
  [setOwnersData](state: PclState, payload: any): void {
    const data: pcldto.pclOwner[] = payload.data;
    const skip: number = payload.skip;

    if (skip === 0) {
      state.ownersData = data;
    } else {
      state.ownersData.push(...data);
    }
  },
  [setOwnersDataCount](state: PclState, payload: number): void {
    state.ownersDataCount = payload;
  },
  [setOwnersDataSearchText](state: PclState, payload: string | null): void {
    state.ownersDataSearchText = payload;
  },
  [setOwnersDataOData](state: PclState, payload: ODataProps): void {
    state.ownersDataOData = payload;
  },
  [setDivisionRegionResults](state: PclState, payload: pcldto.pclDivisionRegion[]): void {
    state.divisionRegionResults = payload;
  },
  [setDistrictResults](state: PclState, payload: pcldto.pclDistrict[]): void {
    state.districtResults = payload;
  },
  [setMarketResults](state: PclState, payload: pcldto.pclMarket[]): void {
    state.marketResults = payload;
  },
  [setDeliveryAreaLookup](state: PclState, payload: any): void {
    // add a blank first option
    if (payload && payload.length > 0 && payload[0].DeliveryAreaMiles !== null) {
      payload.unshift({ DeliveryAreaMiles: null });
    }
    state.deliveryAreaLookup = payload;
  },
  [setSocialMediaLookup](state: PclState, payload: SocialMedia[]): void {
    state.socialMediaLookup = payload;
  },
};
