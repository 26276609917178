import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { record_type, string_type, int32_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";

export class Brand extends Record {
    constructor(BrandId, Name, Number$) {
        super();
        this.BrandId = (BrandId | 0);
        this.Name = Name;
        this.Number = (Number$ | 0);
    }
}

export function Brand_$reflection() {
    return record_type("LookupModels.Brand", [], Brand, () => [["BrandId", int32_type], ["Name", string_type], ["Number", int32_type]]);
}

export class Manufacturer extends Record {
    constructor(ManufacturerAccountNumberId, Name, Number$) {
        super();
        this.ManufacturerAccountNumberId = (ManufacturerAccountNumberId | 0);
        this.Name = Name;
        this.Number = Number$;
    }
}

export function Manufacturer_$reflection() {
    return record_type("LookupModels.Manufacturer", [], Manufacturer, () => [["ManufacturerAccountNumberId", int32_type], ["Name", string_type], ["Number", string_type]]);
}

export class Vendor extends Record {
    constructor(VendorId, Name, Number$) {
        super();
        this.VendorId = (VendorId | 0);
        this.Name = Name;
        this.Number = Number$;
    }
}

export function Vendor_$reflection() {
    return record_type("LookupModels.Vendor", [], Vendor, () => [["VendorId", int32_type], ["Name", string_type], ["Number", string_type]]);
}

export class DistributionCenter extends Record {
    constructor(DistributionCenterId, Name) {
        super();
        this.DistributionCenterId = (DistributionCenterId | 0);
        this.Name = Name;
    }
}

export function DistributionCenter_$reflection() {
    return record_type("LookupModels.DistributionCenter", [], DistributionCenter, () => [["DistributionCenterId", int32_type], ["Name", string_type]]);
}

export class PlanogramLocationGroup extends Record {
    constructor(PlanogramLocationGroupId, Name) {
        super();
        this.PlanogramLocationGroupId = (PlanogramLocationGroupId | 0);
        this.Name = Name;
    }
}

export function PlanogramLocationGroup_$reflection() {
    return record_type("LookupModels.PlanogramLocationGroup", [], PlanogramLocationGroup, () => [["PlanogramLocationGroupId", int32_type], ["Name", string_type]]);
}

export class ReasonForNewItem extends Record {
    constructor(ReasonForNewItemId, Reason) {
        super();
        this.ReasonForNewItemId = (ReasonForNewItemId | 0);
        this.Reason = Reason;
    }
}

export function ReasonForNewItem_$reflection() {
    return record_type("LookupModels.ReasonForNewItem", [], ReasonForNewItem, () => [["ReasonForNewItemId", int32_type], ["Reason", string_type]]);
}

export class PspEmployee extends Record {
    constructor(PspEmployeeId, FirstName, LastName) {
        super();
        this.PspEmployeeId = (PspEmployeeId | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
    }
}

export function PspEmployee_$reflection() {
    return record_type("LookupModels.PspEmployee", [], PspEmployee, () => [["PspEmployeeId", int32_type], ["FirstName", string_type], ["LastName", string_type]]);
}

export class UnitOfMeasure extends Record {
    constructor(UnitOfMeasureId, UnitOfMeasure) {
        super();
        this.UnitOfMeasureId = (UnitOfMeasureId | 0);
        this.UnitOfMeasure = UnitOfMeasure;
    }
}

export function UnitOfMeasure_$reflection() {
    return record_type("LookupModels.UnitOfMeasure", [], UnitOfMeasure, () => [["UnitOfMeasureId", int32_type], ["UnitOfMeasure", string_type]]);
}

export class ProcessFileStatus extends Record {
    constructor(ProcessFileStatusId, Description) {
        super();
        this.ProcessFileStatusId = (ProcessFileStatusId | 0);
        this.Description = Description;
    }
}

export function ProcessFileStatus_$reflection() {
    return record_type("LookupModels.ProcessFileStatus", [], ProcessFileStatus, () => [["ProcessFileStatusId", int32_type], ["Description", string_type]]);
}

export class ProcessingProfile extends Record {
    constructor(ProcessingProfileId, FriendlyName, PipelineName) {
        super();
        this.ProcessingProfileId = (ProcessingProfileId | 0);
        this.FriendlyName = FriendlyName;
        this.PipelineName = PipelineName;
    }
}

export function ProcessingProfile_$reflection() {
    return record_type("LookupModels.ProcessingProfile", [], ProcessingProfile, () => [["ProcessingProfileId", int32_type], ["FriendlyName", string_type], ["PipelineName", string_type]]);
}

