<template>
  <core-view-template title="Manage Seasons">
    <v-row>
      <v-col>
        <v-text-field v-model="newSeason" label="New Season" clearable />
      </v-col>
      <v-col>
        <v-btn @click="addSeason" :disabled="!newSeason" :loading="loading">
          <v-icon>mdi-plus</v-icon>
          Add Season
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="seasonItems"
          :loading="loading"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn @click="confirmDelete(item.Value)" small>
              <v-icon small>mdi-delete</v-icon>
              Delete
            </v-btn>
          </template>
        </v-data-table></v-col
      >
    </v-row>
    <v-dialog v-model="showConfirmDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this season?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="proceedDelete">Yes</v-btn>
          <v-btn text @click="cancelDelete">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="clear: both; height: 50px"></div>
  </core-view-template>
</template>
<script setup lang="ts">
import { usePrebookStore } from '@/stores/prebookstore';
import { computed, defineComponent, onMounted, ref } from 'vue';
import * as domain from '@psp/pogona_vendor_domain';
import * as futil from '@/utils/FableUtils.fs.js';
defineComponent({
  name: 'ManageSeasons',
});
const headers = [
  { text: 'Season', value: 'Text', sortable: true },
  { text: 'Actions', value: 'actions', sortable: false },
];
const emit = defineEmits(['snackbar-success', 'snackbar-error']);
const pbstore = usePrebookStore();
const loading = ref(false);
const newSeason = ref('');
const showConfirmDialog = ref(false);
const seasonToDelete = ref<domain.PrebookMonthSeason_$union | null>(null);
function confirmDelete(season: domain.PrebookMonthSeason_$union) {
  seasonToDelete.value = season;
  showConfirmDialog.value = true;
}
function cancelDelete() {
  showConfirmDialog.value = false;
  seasonToDelete.value = null;
}
async function proceedDelete() {
  if (seasonToDelete.value) {
    await deleteSeason(seasonToDelete.value);
    showConfirmDialog.value = false;
    seasonToDelete.value = null;
  }
}
async function deleteSeason(season: domain.PrebookMonthSeason_$union) {
  const updatedSeasonResult = domain.SeasonLookupFunctions_deleteSeason(
    pbstore.seasonLookup as domain.SeasonLookup,
    season,
  );
  if (futil.isOk(updatedSeasonResult)) {
    pbstore.seasonLookup = futil.getOk(updatedSeasonResult);
    try {
      loading.value = true;
      await pbstore.saveSeasonLookup(pbstore.seasonLookup as domain.SeasonLookup);
    } finally {
      loading.value = false;
    }
    emit('snackbar-success', 'Season deleted');
  } else {
    emit('snackbar-error', 'Failed to delete season');
  }
}
async function addSeason() {
  if (newSeason.value) {
    const exists = domain.SeasonLookupFunctions_seasonExists(
      pbstore.seasonLookup as domain.SeasonLookup,
      newSeason.value,
    );
    if (exists) {
      emit('snackbar-error', 'Season already exists');
    } else {
      const season = domain.SeasonLookupFunctions_textToSeason(newSeason.value);
      const updatedSeasonResult = domain.SeasonLookupFunctions_addSeason(
        pbstore.seasonLookup as domain.SeasonLookup,
        season,
      );
      if (futil.isOk(updatedSeasonResult)) {
        pbstore.seasonLookup = futil.getOk(updatedSeasonResult);
        try {
          loading.value = true;
          await pbstore.saveSeasonLookup(pbstore.seasonLookup as domain.SeasonLookup);
        } finally {
          loading.value = false;
        }
        newSeason.value = '';
        emit('snackbar-success', 'Season added');
      } else {
        emit('snackbar-error', 'Failed to add season');
      }
    }
  }
}
const seasonItems = computed(() => {
  return pbstore.seasonLookup
    ? domain.SeasonLookupFunctions_getSeasonTextArray(pbstore.seasonLookup as domain.SeasonLookup)
    : [];
});
async function getSeasonLookup() {
  try {
    loading.value = true;
    await pbstore.getSeasonLookup();
  } finally {
    loading.value = false;
  }
}
onMounted(async () => {
  await getSeasonLookup();
});
</script>
