<template>
  <v-col>
    <v-row>
      <v-col>
        <v-text-field
          v-model="setIdSearch"
          :label="
            selectedTab === 'all'
              ? 'Search (UPC, item, desc, or POG ID)'
              : 'Search (POG ID or description)'
          "
          @click:clear="
            setIdSearch = null;
            getDataForTab();
          "
          v-debounce:300="getDataForTab"
          :clearable="true"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="newStoreSetType ? 'hide-tabs' : ''">
        <v-tabs fixed-tabs v-model="selectedTab">
          <v-tab href="#sets">Store Sets</v-tab>
          <v-tab href="#endCaps" v-if="newStoreSetType === false">Monthly AD Endcaps</v-tab>
          <v-tab href="#all" v-if="newStoreSetType === false && storeSetStageType === false"
            >Company POG</v-tab
          >
          <v-tab href="#pendingSets">Pending Store Sets</v-tab>

          <v-tab-item value="sets">
            <v-data-table
              v-if="storeSet"
              v-model="storeSet.selected"
              :headers="storeSet.headers"
              :items="storeSet.items"
              :loading="storeSet.loading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="storeSet.options"
              @click:row="clickStoreSetRow"
              @item-selected="selectStoreSetRow"
              @update:sort-by="storeSet.get(storeSetAdditionalFilter)"
              @toggle-select-all="storeSet.toggleSelectAll(storeSet, $event)"
              item-key="setId"
              show-select
              class="elevation-1 scrollable"
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="storeSet"
                  :additionalFilter="storeSetAdditionalFilter"
                  :colspan="storeSet && storeSet.headers ? storeSet.headers.length : 0"
                ></infinite-loader>
              </template>

              <template v-slot:item.printSequence="props">
                <v-edit-dialog
                  :return-value.sync="props.item.printSequence"
                  @save="savePrintSequenceEdit(props.item)"
                  @open="openPrintSequenceEdit(props.item)"
                  @close="closePrintSequenceEdit(props.item)"
                  @cancel="closePrintSequenceEdit(props.item)"
                >
                  <span class="underline">{{ props.item.printSequence }}</span>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.printSequence"
                      :rules="printSequenceRules"
                      label="Edit"
                      single-line
                      number
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.setId="props" v-if="canSwapStoreSets">
                <span class="underline" @click="openSwapSetDialog(props.item, false)">{{
                  props.item.setId
                }}</span>
              </template>
              <template v-slot:item.setId="{ item }" v-else>{{ item.setId }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="storeSet"></infinite-paganation>
            <v-dialog v-model="swapSetDialog" max-width="600">
              <v-card>
                <v-card-title>
                  <span class="headline"
                    >Swap Set ID {{ swapSelectedSet ? swapSelectedSet.setId : '' }}</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-row justify="center">
                    <v-col>
                      <v-row>
                        <span>Available Sets</span>
                      </v-row>
                      <v-row>
                        <v-text-field
                          prepend-icon="search"
                          v-model="swapSetsSearch"
                          v-debounce:300="filterSwapStoreSets"
                          @click:clear="
                            swapSetsSearch = null;
                            filterSwapStoreSets();
                          "
                          label="Search (POG ID, Description)"
                          :clearable="true"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-data-table
                          :headers="swapSetHeaders"
                          :items="availableStoreSetsView"
                          :loading="availableStoreSetsLoading"
                          :options="{ itemsPerPage: 9999 }"
                          item-key="setId"
                          class="elevation-1 scrollable"
                        >
                          <template v-slot:item.swap="{ item }">
                            <v-btn @click="confirmSwapMapping(item)">Swap</v-btn>
                          </template>
                        </v-data-table>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="swapSetDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showSwapConfirmDialog" persistent max-width="400">
              <v-card>
                <v-card-title>
                  <span class="headline">Confirm Set Swap</span>
                </v-card-title>
                <v-card-text
                  >Are you sure you want swap set ID
                  {{ swapSelectedSet ? swapSelectedSet.setId : '' }} '{{
                    swapSelectedSet ? swapSelectedSet.description : ''
                  }}' for ID {{ setToSwap ? setToSwap.setId : '' }} '{{
                    setToSwap ? setToSwap.description : ''
                  }}'?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="swapMapping(setToSwap, swapSelectedSet)"
                    :disabled="swapSetLoading"
                    :loading="swapSetLoading"
                    >Yes</v-btn
                  >
                  <v-btn
                    @click="showSwapConfirmDialog = false"
                    :disabled="swapSetLoading"
                    :loading="swapSetLoading"
                    >No</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <v-tab-item value="endCaps" v-if="newStoreSetType === false">
            <v-data-table
              v-if="storeSetEndCaps"
              v-model="storeSetEndCaps.selected"
              :headers="storeSetEndCaps.headers"
              :items="storeSetEndCaps.items"
              :loading="storeSetEndCaps.loading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="storeSetEndCaps.options"
              @click:row="clickStoreSetRow"
              @item-selected="selectStoreSetRow"
              @update:sort-by="storeSetEndCaps.get(storeSetAdditionalFilter)"
              @toggle-select-all="storeSetEndCaps.toggleSelectAll(storeSetEndCaps, $event)"
              item-key="setId"
              show-select
              class="elevation-1 scrollable"
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="storeSetEndCaps"
                  :additionalFilter="storeSetAdditionalFilter"
                  :colspan="
                    storeSetEndCaps && storeSetEndCaps.headers ? storeSetEndCaps.headers.length : 0
                  "
                ></infinite-loader>
              </template>

              <template v-slot:item.printSequence="props">
                <v-edit-dialog
                  :return-value.sync="props.item.printSequence"
                  @save="savePrintSequenceEdit(props.item)"
                  @open="openPrintSequenceEdit(props.item)"
                  @close="closePrintSequenceEdit(props.item)"
                  @cancel="closePrintSequenceEdit(props.item)"
                >
                  <span class="underline">{{ props.item.printSequence }}</span>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.printSequence"
                      :rules="printSequenceRules"
                      label="Edit"
                      single-line
                      number
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.setId="props" v-if="canSwapStoreSets">
                <span class="underline" @click="openSwapSetDialog(props.item, true)">{{
                  props.item.setId
                }}</span>
              </template>
              <template v-slot:item.setId="{ item }" v-else>{{ item.setId }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="storeSetEndCaps"></infinite-paganation>
          </v-tab-item>

          <v-tab-item value="all" v-if="newStoreSetType === false && storeSetStageType === false">
            <v-data-table
              v-if="allPogItems"
              v-model="allPogItems.selected"
              :headers="allPogItems.headers"
              :items="allPogItems.items"
              :loading="allPogItems.loading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="allPogItems.options"
              @update:sort-by="allPogItems.get(allPogItemsAdditionalFilter)"
              item-key="compositeId"
              class="elevation-1 scrollable"
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="allPogItems"
                  :additionalFilter="allPogItemsAdditionalFilter"
                  :colspan="allPogItems && allPogItems.headers ? allPogItems.headers.length : 0"
                ></infinite-loader>
              </template>

              <template v-slot:item.setSectionId="{ item }">{{
                item.setSectionId ? item.setSectionId.toString().padStart(2, '0') : ''
              }}</template>

              <template v-slot:item.setPrintOrder="{ item }">{{
                item.setSectionId ? item.setPrintOrder.toString().padStart(4, '0') : ''
              }}</template>

              <template v-slot:item.setShelfId="{ item }">{{
                item.setSectionId ? item.setShelfId.toString().padStart(2, '0') : ''
              }}</template>

              <template v-slot:item.setShelfPosition="{ item }">{{
                item.setSectionId ? item.setShelfPosition.toString().padStart(2, '0') : ''
              }}</template>
            </v-data-table>
            <infinite-paganation :pogonaTable="allPogItems"></infinite-paganation>
          </v-tab-item>

          <v-tab-item value="pendingSets">
            <v-data-table
              v-if="pendingStoreSet"
              v-model="pendingStoreSet.selected"
              :headers="pendingStoreSet.headers"
              :items="pendingStoreSet.items"
              :loading="pendingStoreSet.loading"
              :items-per-page="50"
              :disable-pagination="true"
              :fixed-header="true"
              :options.sync="pendingStoreSet.options"
              @click:row="clickStoreSetRow"
              @item-selected="selectStoreSetRow"
              @update:sort-by="pendingStoreSet.get(storeSetAdditionalFilter)"
              @toggle-select-all="pendingStoreSet.toggleSelectAll(pendingStoreSet, $event)"
              item-key="pendingStoreSetID"
              show-select
              class="elevation-1 scrollable"
            >
              <template v-slot:item.datePending="{ value }">
                {{ value | formatDate }}
              </template>

              <template v-slot:item.dateActive="{ value }">
                {{ value | formatDate }}
              </template>

              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="pendingStoreSet"
                  :additionalFilter="storeSetAdditionalFilter"
                  :colspan="
                    pendingStoreSet && pendingStoreSet.headers ? pendingStoreSet.headers.length : 0
                  "
                ></infinite-loader>
              </template>
            </v-data-table>
            <infinite-paganation :pogonaTable="pendingStoreSet"></infinite-paganation>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import Vue from 'vue';
import PogonaDataTable from '@/utils/PogonaDataTable';

const StoreSet = Vue.component('store-set', {
  props: {
    value: {
      required: true,
    },
    setType: {
      type: Number,
      required: true,
    },
    importId: {
      type: Number,
      required: false,
    },
    storeNumberOverride: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      storeSet:
        this.setType === 1
          ? this.$store.state.storeset.storeSet
          : (this.setType === 2
              ? this.$store.state.newstoreset.storeSet
              : this.setType === 3
                ? this.$store.state.storesetstage.storeSet
                : null) || this.value,

      pendingStoreSet:
        this.setType === 1
          ? this.$store.state.storeset.pendingStoreSet
          : (this.setType === 2
              ? null
              : this.setType === 3
                ? this.$store.state.storesetstage.pendingStoreSet
                : null) || this.value,

      storeSetEndCaps:
        this.setType === 1
          ? this.$store.state.storeset.storeSetEndCaps
          : (this.setType === 2
              ? this.$store.state.newstoreset.storeSetEndCaps
              : this.setType === 3
                ? this.$store.state.storesetstage.storeSetEndCaps
                : null) || null,
      allPogItems: this.setType === 1 ? this.$store.state.storeset.allPogItems : null,
      storeSetEditBefore: null,
      storeSetEditValid: false,
      printSequenceEditOpen: false,
      isLabelAdmin: false,
      swapSetDialog: false,
      swapSelectedSet: null,
      swapSetsSearch: null,
      swapSetLoading: false,
      showSwapConfirmDialog: false,
      setToSwap: null,
      swapSetHeaders: [
        {
          text: 'ID',
          value: 'setId',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Size',
          value: 'setSize',
        },
        {
          text: 'Swap',
          value: 'swap',
        },
      ],
      availableStoreSetsView: [],
      availableStoreSetsLoading: false,
      printSequenceRules: [
        v => {
          if (isNaN(v) === true) {
            return 'Print sequence must be a number.';
          }

          if (v < 1) {
            v = 1;
          }
          return true;
        },
      ],
      itemsSelected: [],
    };
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    storeSetStore() {
      switch (this.setType) {
        case 1:
          return this.$store.state.storeset;
        case 2:
          return this.$store.state.newstoreset;
        case 3:
          return this.$store.state.storesetstage;
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    storeSetStoreString() {
      switch (this.setType) {
        case 1:
          return 'storeset';
        case 2:
          return 'newstoreset';
        case 3:
          return 'storesetstage';
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    selectedTab: {
      get() {
        return this.storeSetStore.selectedTab || null;
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/selectedTab', val);
      },
    },
    setSections: {
      get() {
        return this.storeSetStore.setSections || [];
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/setSections', val);
      },
    },
    setIdSearch: {
      get() {
        return this.storeSetStore.setIdSearch || '';
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/setIdSearch', val);
      },
    },
    assignedSets: {
      get() {
        return this.storeSetStore.assignedSets || [];
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/assignedSets', val);
      },
    },
    allStoreSets: {
      get() {
        return this.storeSetStore.allStoreSets || [];
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/allStoreSets', val);
      },
    },
    allStoreSetsEndCaps: {
      get() {
        return this.storeSetStore.allStoreSetsEndCaps || [];
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/allStoreSetsEndCaps', val);
      },
    },
    canSwapStoreSets() {
      return this.isLabelAdmin === true && this.setType === 3;
    },
    newStoreSetType() {
      return this.setType === 2;
    },
    storeSetStageType() {
      return this.setType === 3;
    },
    selectedStoreSet() {
      if (this.selectedTab === 'endCaps') {
        return this.storeSetEndCaps;
      } else if (this.selectedTab === 'pendingSets') {
        return this.pendingStoreSet;
      }
      return this.storeSet;
    },
    storeSetBaseUrl() {
      switch (this.setType) {
        case 1:
          return `label/storeset/store/${this.storeNumber}`;
        case 2:
          return `label/storeset/newstore/${this.importId}`;
        case 3:
          return `label/storesetstage/store/${this.storeNumberOverride || this.storeNumber}`;
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    pendingStoreSetBaseUrl() {
      return `label/storeset/pending/${this.storeNumberOverride || this.storeNumber}`;
    },
    selectedCount() {
      return this.selectedStoreSet && this.selectedStoreSet.selected
        ? this.selectedStoreSet.selected.length
        : 0;
    },
    storeSetDefaultFilter() {
      switch (this.setType) {
        case 1:
        case 3:
          return f('and').eq('isEndCap', false);
        case 2:
          return f('and').eq('storeNumber', this.storeNumberOverride);
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    storeSetEndCapsDefaultFilter() {
      switch (this.setType) {
        case 1:
        case 3:
          return f('and').eq('isEndCap', true).eq('storeAssigned', false);
        case 2:
          return f('and').eq('storeNumber', this.storeNumberOverride);
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    pendingStoreSetDefaultFilter() {
      return f('and').gt('dateActive', new Date());
    },
  },
  async mounted() {
    this.$emit('update:selectedTab', this.selectedTab);

    this.isLabelAdmin = await this.$authApi.roleHasRights('LabelAdmin');
    await this.getData(true);
  },
  methods: {
    setupEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting store sets',
          err,
          id: '411a39bc-084b-4671-a394-d6de3228a323',
        });
      };
      if (this.storeSet.removeListener) {
        this.storeSet.removeListener('error', error);
      }
      if (this.storeSet.on) {
        this.storeSet.on('error', error);
      }
    },
    setupPendingEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting pending store sets',
          err,
          id: '3c1cbcf2-0eac-4769-91ce-2835899b3294',
        });
      };

      if (this.pendingStoreSet.removeListener) {
        this.pendingStoreSet.removeListener('error', error);
      }
      if (this.pendingStoreSet.on) {
        this.pendingStoreSet.on('error', error);
      }
    },
    setupCompanyPogEvents() {
      const error = err => {
        this.$emit('snackbar-error', {
          text: 'Error getting store sets',
          err,
          id: '516ebf36-e5d1-4792-8c50-9dffa52e555d',
        });
      };
      if (this.allPogItems.removeListener) {
        this.allPogItems.removeListener('error', error);
      }
      if (this.allPogItems.on) {
        this.allPogItems.on('error', error);
      }
    },
    async getDataForTab() {
      if (this.selectedTab === 'sets') {
        // company pog items
        this.initStoreSets();
        await this.storeSet.get(this.storeSetAdditionalFilter);
      } else if (this.selectedTab === 'all') {
        // company pog items
        this.initAllPogItems();
        await this.allPogItems.get(this.allPogItemsAdditionalFilter);
      } else if (this.selectedTab === 'endCaps') {
        // ad end caps
        this.initStoreSetEndCaps();
        await this.storeSetEndCaps.get(this.storeSetAdditionalFilter);
      } else if (this.selectedTab === 'pendingSets') {
        // pending sets
        this.initPendingStoreSets();
        await this.pendingStoreSet.get(this.storeSetAdditionalFilter);
      }
    },
    async getData(mounted) {
      if (
        (mounted === true &&
          (!this.storeSet ||
            !this.storeSet.inited ||
            !this.allPogItems ||
            !this.allPogItems.inited ||
            !this.storeSetEndCaps ||
            !this.storeSetEndCaps.inited ||
            !this.pendingStoreSet ||
            !this.pendingStoreSet.inited)) ||
        mounted !== true
      ) {
        // store sets
        await this.getDataForTab();

        // only needed for admins
        if (this.isLabelAdmin === true) {
          const initData = await Promise.all([
            this.$authApi.http.get(
              `label/storesetassignstage?$filter=storeNumber eq '${
                this.storeNumberOverride || this.storeNumber
              }'&$top=99999&`,
            ),
            this.$authApi.http.get(
              'label/storesetstage?$top=99999&$orderBy=setId&$filter=isEndCap eq false',
            ),
            this.$authApi.http.get(
              'label/storesetstage?$top=99999&$orderBy=setId&$filter=isEndCap eq true and storeAssigned eq false',
            ),
          ]);

          this.assignedSets = initData[0].data;
          this.allStoreSets = initData[1].data;
          this.allStoreSetsEndCaps = initData[2].data;
        }
      }
    },
    storeSetAdditionalFilter() {
      if (this.setIdSearch && this.setIdSearch.length > 0) {
        const setIdSearchString = this.setIdSearch.replace(/^0*/, '').trim();
        return f('or')
          .contains("'setId'", setIdSearchString)
          .contains("'description'", this.setIdSearch.trim().toUpperCase());
      }
      return null;
    },
    allPogItemsAdditionalFilter() {
      if (this.setIdSearch && this.setIdSearch.length > 0) {
        const setIdSearchString = this.setIdSearch.replace(/^0*/, '').trim();
        return f('or')
          .contains("'upc'", setIdSearchString)
          .contains("'itemId'", setIdSearchString)
          .contains("'fulldescription1'", setIdSearchString)
          .contains("'fulldescription2'", setIdSearchString)
          .contains("'setId'", setIdSearchString);
      }
      return null;
    },
    async clickStoreSetRow(event) {
      const selectedTest = this.selectedStoreSet.selected.filter(x => x.setId === event.setId);
      const selected = selectedTest && selectedTest.length > 0;

      await this.selectStoreSetRow({ item: event, value: !selected });
    },
    async selectStoreSetRow(event) {
      // If the print sequence window is open, don't fetch the item data.
      if (this.printSequenceEditOpen === true || this.swapSetDialog === true) {
        // Clear the data grid
        if (event.item && event.value === false) {
          this.storeSetData = null;
        }
        return;
      }

      if (event.value === true) {
        this.selectedStoreSet.selected.push(event.item);
        this.$emit('input', this.selectedStoreSet);
      } else {
        const selectedIndex = this.selectedStoreSet.selected.findIndex(
          x => x.setId == event.item.setId,
        );
        if (selectedIndex > -1) {
          this.selectedStoreSet.selected.splice(selectedIndex, 1);
          this.$emit('input', this.selectedStoreSet);
        }
      }
    },
    openPrintSequenceEdit(item) {
      // clone the item
      this.storeSetEditBefore = Object.assign({}, item);
      this.printSequenceEditOpen = true;
    },
    closePrintSequenceEdit(item) {
      this.printSequenceEditOpen = false;
      if (this.storeSetEditValid === false) {
        // Set back the print sequence
        this.selectedStoreSet.items.filter(
          i => i.storeSetAssignId === item.storeSetAssignId,
        )[0].printSequence = this.storeSetEditBefore.printSequence;
      }
    },
    cancelPrintSequenceEdit() {
      this.printSequenceEditOpen = false;
    },
    async savePrintSequenceEdit(item) {
      try {
        this.storeSetEditValid = isNaN(item.printSequence) === false;

        if (isNaN(item.printSequence) === false) {
          let patchUrl = 'label/';
          switch (this.setType) {
            case 1:
              patchUrl += 'storesetassign';
              break;
            case 2:
              patchUrl += 'newstoresetassign';
              break;
            case 3:
              patchUrl += 'storesetassignstage';
              break;
            default:
              throw new Error(`Set type ${this.setType} not supported`);
          }

          patchUrl += `/${item.storeSetAssignId}`;

          await this.$authApi.http.patch(patchUrl, {
            printSequence: item.printSequence,
          });

          // if this is this.setType === 1 then we also want to update stage
          if (this.setType === 1) {
            const stageData = (
              await this.$authApi.http.get(
                `label/storesetassignstage?$filter=setId eq ${item.setId} and storeNumber eq '${this.storeNumber}'`,
              )
            ).data;

            if (stageData && stageData.length === 1) {
              await this.$authApi.http.patch(
                `label/storesetassignstage/${stageData[0].storeSetAssignStageId}`,
                {
                  printSequence: item.printSequence,
                },
              );
            }
          }
        }
      } catch (err) {
        this.$error(err);
        this.$emit('snackbar-error', {
          text: 'Error updating set print sequence.',
          err,
          id: 'd2b9f31f-4732-4455-9992-e1220495a98c',
        });

        // Reset the grids
        this.storeSetData = null;
        await this.selectedStoreSet.get(this.storeSetAdditionalFilter);
      }
    },
    async swapMapping(item, swapSelectedSet) {
      try {
        this.swapSetLoading = true;
        await this.$authApi.http.post(
          `label/storesetassignstage/${swapSelectedSet.storeSetAssignId}/swap/${item.setId}`,
        );

        await this.getData();
        this.$emit('swappedSet');

        this.swapSetDialog = false;
        this.showSwapConfirmDialog = false;
        this.swapSetLoading = false;
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Failed to swap store set',
          err,
          id: '9434f5d2-736a-4c25-aec9-35d3297b4a71',
        });
        this.swapSetLoading = false;
      }
    },
    filterSwapStoreSets() {
      if (this.swapSetsSearch && this.swapSetsSearch.trim().length > 0) {
        const searchValue = this.swapSetsSearch.trim().toUpperCase();
        const filteredSwappedStoreSets = this.allStoreSets.filter(
          x =>
            // eslint-disable-next-line implicit-arrow-linebreak
            x.setId.toString().indexOf(searchValue) > -1 ||
            (x.description && x.description.toUpperCase().indexOf(searchValue)) > -1,
        );
        this.availableStoreSetsView = filteredSwappedStoreSets;
      } else {
        this.availableStoreSetsView = this.allStoreSets;
      }

      if (this.swapSelectedSet) {
        // filter
        this.availableStoreSetsView = this.availableStoreSetsView.filter(
          x => x.setId !== this.swapSelectedSet.setId,
        );
      }
    },
    confirmSwapMapping(item) {
      this.setToSwap = item;
      this.showSwapConfirmDialog = true;
    },
    openSwapSetDialog(item, isEndCap) {
      this.swapSetDialog = true;
      this.swapSelectedSet = item;
      const filterAvailableStoreSets = isEc => {
        // filter out set ID from all store sets
        this.availableStoreSetsView = this.filterAvailableStoreSets(
          isEc ? this.allStoreSetsEndCaps : this.allStoreSets,
          item,
        );
      };
      // we do it this way so the dialog shows right away
      setTimeout(filterAvailableStoreSets.bind(this, isEndCap), 0);
    },
    filterAvailableStoreSets(storeSets, item) {
      let filteredStoreSets = storeSets;
      if (item) {
        filteredStoreSets = filteredStoreSets.filter(x => x.setId !== item.setId);
      }

      if (this.assignedSets && this.assignedSets.length > 0) {
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const assignedSet of this.assignedSets) {
          const filteredStoreSetsIx = filteredStoreSets.findIndex(
            x => x.setId === assignedSet.setId,
          );
          if (filteredStoreSetsIx > -1) {
            // remove store set
            filteredStoreSets.splice(filteredStoreSetsIx, 1);
          }
        }
      }

      return filteredStoreSets;
    },
    initStoreSets() {
      this.storeSet = new PogonaDataTable({
        headers: [
          {
            text: 'P/Seq',
            value: 'printSequence',
          },
          {
            text: 'POG ID',
            value: 'setId',
          },
          {
            text: 'POG Description',
            value: 'description',
          },
          {
            text: 'Size',
            value: 'setSize',
          },
        ],
        baseUrl: this.storeSetBaseUrl,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['printSequence'] },
        onPageFilter: () => this.storeSetAdditionalFilter,
        defaultFilter: this.storeSetDefaultFilter,
        isInfinite: true,
        multiselect: true,
      });
      this.setupEvents();
    },
    initPendingStoreSets() {
      this.pendingStoreSet = new PogonaDataTable({
        headers: [
          {
            text: 'POG ID',
            value: 'setId',
          },
          {
            text: 'POG Description',
            value: 'description',
          },
          {
            text: 'Size',
            value: 'setSize',
          },
          {
            text: 'Date Active',
            value: 'dateActive',
          },
        ],
        baseUrl: this.pendingStoreSetBaseUrl,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['setId'] },
        onPageFilter: () => this.storeSetAdditionalFilter,
        defaultFilter: this.pendingStoreSetDefaultFilter,
        isInfinite: true,
        multiselect: true,
      });
      this.setupPendingEvents();
    },
    initStoreSetEndCaps() {
      this.storeSetEndCaps = new PogonaDataTable({
        headers: [
          {
            text: 'P/Seq',
            value: 'printSequence',
          },
          {
            text: 'POG ID',
            value: 'setId',
          },
          {
            text: 'POG Description',
            value: 'description',
          },
          {
            text: 'Size',
            value: 'setSize',
          },
        ],
        baseUrl: this.storeSetBaseUrl,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['printSequence'] },
        onPageFilter: () => this.storeSetAdditionalFilter,
        defaultFilter: this.storeSetEndCapsDefaultFilter,
        isInfinite: true,
        multiselect: true,
      });
      this.storeSetEndCaps.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting store sets',
          err,
          id: '516ebf36-e5d1-4792-8c50-9dffa52e555d',
        });
      });
    },
    initAllPogItems() {
      this.allPogItems = new PogonaDataTable({
        headers: [
          {
            text: 'UPC #',
            value: 'upc',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
          {
            text: 'Description 1',
            value: 'fulldescription1',
          },
          {
            text: 'Description 2',
            value: 'fulldescription2',
          },
          {
            text: 'Unit Size',
            value: 'unitsize',
          },
          {
            text: 'UOM',
            value: 'unitmeasure',
          },
          {
            text: 'Size',
            value: 'setSize',
          },
          {
            text: 'POG ID',
            value: 'setId',
          },
          {
            text: 'POG Description',
            value: 'description',
          },
          {
            text: 'Section ID',
            value: 'setSectionId',
          },
          {
            text: 'Print Order',
            value: 'setPrintOrder',
          },
          {
            text: 'Shelf ID',
            value: 'setShelfId',
          },
          {
            text: 'Position ID',
            value: 'setShelfPosition',
          },
          {
            text: 'Facings',
            value: 'facings',
          },
        ],
        baseUrl: 'label/storesetstoreextendeddata/all',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['setId'] },
        onPageFilter: () => this.allPogItemsAdditionalFilter,
        isInfinite: true,
        multiselect: true,
      });
      this.setupCompanyPogEvents();
    },
  },
  watch: {
    storeNumber: {
      handler: async function () {
        await this.getData();
      },
    },
    storeNumberOverride: {
      handler: async function () {
        await this.getData();
      },
    },
    storeSet: {
      handler: function (val) {
        this.$emit('input', this.selectedStoreSet);
        this.$store.commit(this.storeSetStoreString + '/storeSet', val);
      },
      deep: true,
    },
    pendingStoreSet: {
      handler: function (val) {
        this.$store.commit(this.storeSetStoreString + '/pendingStoreSet', val);
      },
      deep: true,
    },
    storeSetEndCaps: {
      handler: function (val) {
        this.$store.commit(this.storeSetStoreString + '/storeSetEndCaps', val);
      },
      deep: true,
    },
    allPogItems: {
      handler: function (val) {
        this.$store.commit(this.storeSetStoreString + '/allPogItems', val);
      },
      deep: true,
    },
    selectedTab: {
      handler: async function (val) {
        if (this.storeSet) {
          this.storeSet.allSelected = false;
          this.storeSet.selected = [];
        }
        if (this.storeSetEndCaps) {
          this.storeSetEndCaps.allSelected = false;
          this.storeSetEndCaps.selected = [];
        }
        if (this.pendingStoreSet) {
          this.pendingStoreSet.allSelected = false;
          this.pendingStoreSet.selected = [];
        }

        this.$emit('input', this.selectedStoreSet);
        this.$emit('update:selectedTab', val);

        await this.getDataForTab();
      },
    },
    'storeSet.selected': {
      handler: function (val) {
        this.$emit('selectedChange', val);
      },
    },
    selectedStoreSet: {
      handler: function (val) {
        this.$emit('update:selectedStoreSet', val);
      },
      deep: true,
    },
  },
});

export default StoreSet;
export { StoreSet };
</script>

<style lang="scss">
.hide-tabs .v-slide-group {
  display: none !important;
}

.underline {
  cursor: pointer;
}
</style>
