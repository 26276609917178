import { Record, Union } from "../../fable-library-js.4.24.0/Types.js";
import { record_type, class_type, union_type } from "../../fable-library-js.4.24.0/Reflection.js";

export class PrebookItemErrorType extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["TestError"];
    }
}

export function PrebookItemErrorType_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookItemErrorType", [], PrebookItemErrorType, () => [[]]);
}

export class PrebookItemError extends Record {
    constructor(PrebookItemId, ErrorType) {
        super();
        this.PrebookItemId = PrebookItemId;
        this.ErrorType = ErrorType;
    }
}

export function PrebookItemError_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookItemError", [], PrebookItemError, () => [["PrebookItemId", class_type("System.Guid")], ["ErrorType", PrebookItemErrorType_$reflection()]]);
}

