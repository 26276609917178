import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/./Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withBaseUrl, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { routeBuilder } from "./ApiShared.fs.js";
import { toFail, printf, toText } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { IPrebookApi_$reflection } from "../fable_modules/pogona_vendor_api_lib.fable.1.507.0/IPrebookApi.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { startAsPromise } from "../fable_modules/fable-library-js.4.24.0/Async.js";
import { PrebookCommand } from "../fable_modules/pogona_vendor_domain.fable.1.153.0/prebook/PrebookCommands.fs.js";
import { SeasonLookupFunctions_initSeasonLookup } from "../fable_modules/pogona_vendor_domain.fable.1.153.0/prebook/PrebookSeasonLookup.fs.js";

export function createPrebookApiClient(baseUrl, authToken) {
    let options_2;
    return Remoting_buildProxy_64DC51C((options_2 = RemotingModule_withRouteBuilder(routeBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi())), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(authToken), options_2)), IPrebookApi_$reflection());
}

export function prebookApiClient(baseUrl, getToken) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        return ((pr = getToken(), pr.then((token) => createPrebookApiClient(baseUrl, token))));
    }));
}

export function expectPrebookSuccess(commandResult) {
    switch (commandResult.tag) {
        case 0: {
            break;
        }
        case 1: {
            toFail(printf("Prebook command failed %s"))(commandResult.fields[0]);
            break;
        }
        default:
            throw new Error("Unexpected prebook command result");
    }
}

export function handlePrebookCommandCallResult(result) {
    if (result.tag === 1) {
        let arg;
        let copyOfStruct = result.fields[0];
        arg = toString(copyOfStruct);
        return toFail(printf("Prebook command failed %s"))(arg);
    }
    else {
        return result.fields[0];
    }
}

export function savePrebookRequest(prebookRequest, client) {
    let pr_1;
    const pr = startAsPromise(client.processPrebookCommand(new PrebookCommand(0, [prebookRequest, undefined])));
    pr_1 = (pr.then(handlePrebookCommandCallResult));
    return pr_1.then((commandResult) => {
        expectPrebookSuccess(commandResult);
    });
}

export function getPrebookRequest(id, client) {
    const pr = startAsPromise(client.getPrebookRequest(id));
    return pr.then((_arg) => {
        if (_arg.tag === 1) {
            return toFail(printf("Failed to get prebook request %s"))(_arg.fields[0]);
        }
        else {
            return _arg.fields[0];
        }
    });
}

export function saveSeasonLookup(seasonLookup, client) {
    const pr = startAsPromise(client.saveSeasonLookup(seasonLookup));
    return pr.then((_arg) => {
        if (_arg.tag === 1) {
            toFail(printf("Failed to save season lookup %s"))(_arg.fields[0]);
        }
        else {
            _arg.fields[0];
        }
    });
}

export function getSeasonLookup(client) {
    const pr = startAsPromise(client.getSeasonLookup);
    return pr.then((_arg) => {
        if (_arg.tag === 1) {
            return toFail(printf("Failed to get season lookup %s"))(_arg.fields[0]);
        }
        else {
            const seasonLookup = _arg.fields[0];
            if (seasonLookup == null) {
                return SeasonLookupFunctions_initSeasonLookup();
            }
            else {
                return seasonLookup;
            }
        }
    });
}

