<template>
  <core-view-template title="Content Hub Search">
    <core-view-section title="Search">
      <v-row>
        <v-col>
          <v-form @submit.prevent="getData">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="chProductSearchParams.Search"
                  label="Search (item #, UPC, WebID, and/or product name)"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="chProductSearchParams.PCMProductStatusToProduct"
                  label="Status"
                  :items="pcmProductStatusesToProduct"
                  item-text="text"
                  item-value="value"
                  chips
                  multiple
                  clearable
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="chProductSearchParams.ExcludedFromWebsiteToProduct"
                  label="Excluded From web"
                  :items="bools"
                  item-text="text"
                  item-value="value"
                  chips
                  multiple
                  clearable
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="chProductSearchParams.InSet"
                  label="Item in set status"
                  :items="bools"
                  item-text="text"
                  item-value="value"
                  chips
                  multiple
                  clearable
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"
                ><v-btn
                  @click="getData"
                  :disabled="searchingContentHub || canSearch === false"
                  :loading="searchingContentHub"
                  type="submit"
                  >Search</v-btn
                ></v-col
              >
              <v-col><v-btn @click="clear">clear</v-btn></v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </core-view-section>
    <core-view-section title="Results">
      <v-row>
        <v-col
          ><v-btn
            @click="exportData"
            :disabled="searchingContentHub || exportingContentHub || canSearch === false"
            :loading="exportingContentHub"
            >Export</v-btn
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="chProductSearchItems"
            :items-per-page="-1"
            :fixed-header="true"
            :loading="searchingContentHub"
            :footer-props="{
              disableItemsPerPage: true,
              itemsPerPageText: '',
            }"
            :options.sync="options"
            :disable-pagination="true"
            :server-items-length="chProductSearchPayload?.TotalCount ?? 0"
            class="elevation-1 scrollable"
            hide-default-footer
            item-key="ChId"
            @update:options="getData"
            height="100%"
          >
            <template v-slot:item.InStock="{ item }">
              {{ formatters.formatNumberThousands(item.InStock) }}
            </template>
            <template v-slot:item.PCMProductStatusToProduct="{ item }">
              {{ formatPCMProductStatusToProduct(item.PCMProductStatusToProduct) }}
            </template>
            <template v-slot:item.ExcludedFromWebsiteToProduct="{ item }">
              {{ formatters.formatBooleanYesNo(item.ExcludedFromWebsiteToProduct) }}
            </template>
            <template v-slot:item.InSet="{ item }">
              {{ formatters.formatBooleanYesNo(item.InSet) }}
            </template>
            <template v-slot:body.append="{}">
              <tr>
                <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                  <infinite-loading
                    v-if="chProductSearchItems && chProductSearchItems.length > 0"
                    @infinite="infiniteHandler"
                    :identifier="infiniteId"
                  >
                  </infinite-loading>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ><span class="text--black"
            >{{ formatters.formatNumberThousands(chProductSearchItems?.length ?? 0) }} /
            {{ formatters.formatNumberThousands(chProductSearchPayload?.TotalCount ?? 0) }}
          </span></v-col
        >
      </v-row>
    </core-view-section>
  </core-view-template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  useEcomStore,
  PCMProductStatusToProduct,
  ChubProductSearchParams,
  ChubProductSearchPayload,
} from '@/stores/ecomstore';
import { storeToRefs } from 'pinia';
import { useFormatters } from '@/composables/formatters';

const store = useEcomStore();

const {
  chProductSearchPayload,
  chProductSearchParams,
  chProductSearchItems,
  searchingContentHub,
  pcmProductStatusesToProduct,
  exportingContentHub,
} = storeToRefs(store);

const headers: any = ref<any>([
  { value: 'ItemId', text: 'Item #' },
  { value: 'PrimaryUpc', text: 'SKU' },
  { value: 'ProductName', text: 'Product Name' },
  { value: 'PCMProductStatusToProduct', text: 'Status' },
  { value: 'WebId', text: 'WebID' },
  { value: 'ExcludedFromWebsiteToProduct', text: 'Excluded from web?' },
  { value: 'InSet', text: 'Item set status from BY' },
  { value: 'InStock', text: 'Inventory' },
]);
const options = ref<any>({
  sortBy: ['ItemId'],
  sortDesc: [false],
});
const bools = ref<any>([
  {
    text: 'Yes',
    value: true,
  },
  {
    text: 'No',
    value: false,
  },
]);

let infiniteId = new Date().getTime();
const formatters = useFormatters();

async function getData() {
  if (canSearch.value === true) {
    chProductSearchParams.value.Skip = 0;

    if (!chProductSearchParams.value.Search) {
      chProductSearchParams.value.Search = '';
    }

    await store.searchContentHub(options.value);
  }
}

function clear() {
  chProductSearchParams.value = new ChubProductSearchParams();
  chProductSearchPayload.value = new ChubProductSearchPayload();
}

async function exportData() {
  await store.searchContentHubExport(options.value);
}

function infiniteHandler($state: any) {
  if (canSearch.value === true) {
    store
      .searchContentHub(options.value)
      .then(() => {
        if (
          (chProductSearchItems.value?.length ?? 0) <
          (chProductSearchPayload.value?.TotalCount ?? 0)
        ) {
          $state.loaded();
        } else {
          $state.complete();
        }
      })
      .catch(() => {
        $state.complete();
      });
  }
}

function formatPCMProductStatusToProduct(pcmProductStatusToProduct) {
  switch (pcmProductStatusToProduct) {
    case PCMProductStatusToProduct.MerchApproved:
      return 'Merch Approved';
    case PCMProductStatusToProduct.ECommerceReview:
      return 'ECommerce Review';
    case PCMProductStatusToProduct.ServicesApproved:
      return 'Services Approved';
    case PCMProductStatusToProduct.Approved:
      return 'Approved';
    case PCMProductStatusToProduct.Created:
      return 'Created';
    case PCMProductStatusToProduct.Archived:
      return 'Archived';
    case PCMProductStatusToProduct.Draft:
      return 'Draft';
    default:
      return null;
  }
}

const canSearch = computed(() => {
  return (
    (chProductSearchParams.value?.Search?.trim() ?? '').length > 0 ||
    (chProductSearchParams.value?.ExcludedFromWebsiteToProduct.length ?? 0) > 0 ||
    (chProductSearchParams.value?.InSet.length ?? 0) > 0 ||
    (chProductSearchParams.value?.PCMProductStatusToProduct.length ?? 0) > 0
  );
});
</script>
