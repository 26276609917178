<template>
  <core-view-template :title="title">
    <v-container>
      <v-row class="border">
        <v-col>
          <h4>How Does This Work?</h4>
          <p>
            Choose a Category Manager from the drop down, then download the "New Pre-Book Request"
            Template below, fill out your required fields noted with an *, then simply upload the
            Excel file.
          </p>
          <p>Required fields are marked with an <span class="red--text">*</span></p>
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <v-row
            ><v-col><a>TODO: New Pre-Book Request Template download link</a></v-col></v-row
          >
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                label="Upload New Pre-Book Request"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ></v-file-input></v-col
            ><v-col> <v-btn @click="uploadClicked" :disabled="!file">Upload</v-btn></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <span class="red--text float-left mr-3 mt-3">*</span>
          <v-autocomplete
            v-model="categoryManager"
            :items="categoryManagers"
            :item-text="x => `${x.FirstName} ${x.LastName}`"
            :item-value="x => `${x.FirstName} ${x.LastName}`"
            autocomplete="off"
            label="Category Manager"
            clearable
          /> </v-col
      ></v-row>
      <v-row class="border mt-6">
        <v-col>
          <v-row>
            <v-col>
              <v-radio-group v-model="monthOrSeason" row>
                <v-radio label="Month" value="month"></v-radio>
                <v-radio label="Season" value="season"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="monthOrSeason === 'month'" no-gutters>
            <v-col>
              <span class="red--text float-left mr-3 mt-3">*</span>
              <v-autocomplete
                v-model="month"
                :items="monthsSelections"
                item-text="Text"
                item-value="Value"
                autocomplete="off"
                label="Select Month"
                clearable
              />
            </v-col>
          </v-row>

          <v-row v-if="monthOrSeason === 'season'" no-gutters>
            <v-col>
              <span class="red--text float-left mr-3 mt-3">*</span>
              <v-autocomplete
                v-model="season"
                :items="seasonSelections"
                item-text="Text"
                item-value="Value"
                autocomplete="off"
                label="Select Season"
                clearable
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <span class="red--text float-left mr-3 mt-3">*</span>
          <v-autocomplete
            v-model="selectedYear"
            :items="yearSelections"
            autocomplete="off"
            label="Select Year"
            clearable
          />
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <v-row>
            <v-col>
              <v-btn @click="saveClicked" :disabled="!saveEnabled" :loading="loading">{{
                saveButtonTitle
              }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div style="clear: both; height: 50px"></div>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { AllStores, dispatchVendorPortal } from '@/utils/AllStores';
import { useStore } from '@/utils/CompositionApiHelpers';
import * as domain from '@psp/pogona_vendor_domain';
import { usePrebookStore } from '@/stores/prebookstore';
import { useRoute, useRouter } from 'vue-router/composables';
import * as prebookUtils from '@/utils/PrebookUtils.fs.js';
defineComponent({
  name: 'NewPrebook',
});
const emit = defineEmits(['snackbar-success']);
const store = useStore<AllStores>();
const prebookStore = usePrebookStore();
const route = useRoute();
const router = useRouter();
const categoryManager = ref('');
const file = ref<File | null>(null);
const loading = ref(false);
const monthOrSeason = ref<'month' | 'season'>('month');
const month = ref<domain.PrebookMonthSeason_$union | null>(null);
const season = ref<domain.PrebookMonthSeason_$union | null>(null);
const selectedYear = ref<number | null>(null);
const saveButtonTitle = computed(() => (isNewRequest.value ? 'Start Pre-Book Request' : 'Save'));
const categoryManagers = computed(() => store.state.vendorportal.categoryManagers);
function uploadClicked() {
  // TODO: Implement uploadClicked
  alert('TODO: Implement uploadClicked');
}
const title = computed(() => {
  return isNewRequest.value ? 'New Pre-Book Request' : 'Edit Pre-Book Request';
});
const isNewRequest = computed(() => route.params.prebookId === '-');
const yearSelections = computed(() => {
  return domain.PrebookRequestFunctions_yearsToDisplay(new Date().getFullYear());
});
const monthsSelections = computed(() => {
  return domain.PrebookRequestFunctions_monthsToArrayTextValue();
});
const seasonSelections = computed(() =>
  prebookStore.seasonLookup
    ? domain.SeasonLookupFunctions_getSeasonTextArray(
        prebookStore.seasonLookup as domain.SeasonLookup,
      )
    : [],
);
const saveEnabled = computed(() => {
  return categoryManager.value && (month.value || season.value) && selectedYear.value;
});
function getMonthOrSeason(): domain.PrebookMonthSeason_$union | null {
  if (monthOrSeason.value === 'month') {
    return month.value;
  } else {
    return season.value;
  }
}
function fillPrebookRequest(): domain.PrebookRequest {
  const monthOrSeason = getMonthOrSeason();
  if (monthOrSeason === null || selectedYear.value === null) {
    throw new Error('Month/Season and Year are required');
  }
  if (isNewRequest.value) {
    return domain.PrebookRequestFunctions_initNewPrebookRequest(
      categoryManager.value,
      monthOrSeason,
      selectedYear.value ?? 0,
      store.state.app.username,
      new Date(),
    );
  } else if (prebookStore.prebookRequest) {
    const prebook: domain.PrebookRequest = {
      ...prebookStore.prebookRequest,
      CategoryManager: categoryManager.value,
      MonthSeason: monthOrSeason,
      Year: selectedYear.value ?? 0,
      CreatedBy: store.state.app.username,
    } as domain.PrebookRequest;
    return prebook;
  } else {
    throw new Error('Prebook Request not set');
  }
}
async function saveClicked() {
  if (saveEnabled.value) {
    const prebook = fillPrebookRequest();
    try {
      loading.value = true;
      await prebookStore.savePrebookRequest(prebook);
      if (isNewRequest.value) {
        emit('snackbar-success', 'Pre-Book Request Created');
        router.push({ name: 'prebook_prebookrequest', params: { prebookId: prebook.id } });
      } else {
        emit('snackbar-success', 'Pre-Book Request Updated');
        loadPrebookRequest();
      }
    } finally {
      loading.value = false;
    }
  }
}
async function loadPrebookRequest() {
  if (route.params.prebookId && route.params.prebookId !== '-') {
    try {
      loading.value = true;
      await prebookStore.getPrebookRequest(route.params.prebookId);
      categoryManager.value = prebookStore.prebookRequest?.CategoryManager ?? '';
      monthOrSeason.value = prebookUtils.mapMonthSeasonToString(
        prebookStore.prebookRequest?.MonthSeason ?? '',
      );
      if (monthOrSeason.value === 'month') {
        month.value = prebookStore.prebookRequest?.MonthSeason as domain.PrebookMonthSeason_$union;
      } else {
        season.value = prebookStore.prebookRequest?.MonthSeason as domain.PrebookMonthSeason_$union;
      }
      selectedYear.value = prebookStore.prebookRequest?.Year ?? 0;
    } finally {
      loading.value = false;
    }
  } else {
    categoryManager.value = '';
    monthOrSeason.value = 'month';
    month.value = null;
    season.value = null;
    selectedYear.value = null;
  }
}
watch(() => route.params.prebookId, loadPrebookRequest);
onMounted(async () => {
  try {
    loading.value = true;
    await dispatchVendorPortal(store, 'getCategoryManagers', null);
    await loadPrebookRequest();
    await prebookStore.getSeasonLookup();
  } finally {
    loading.value = false;
  }
});
</script>
<style scoped>
.border {
  border: 1px solid #000; /* or any other color */
  border-radius: 4px; /* optional */
  /* Make border color light gray color */
  border-color: #d3d3d3;
}
</style>
