import Home from './components/Home.vue';
import Logout from './components/Logout.vue';
import NotFound from './components/NotFound.vue';
import RenderProgress from './components/SignsAndLabels/RenderProgress.vue';
import BatchCheck from './components/SignsAndLabels/BatchCheck.vue';
import StoreSetPrint from './components/SignsAndLabels/StoreSetPrint.vue';
import HandHeldPrint from './components/SignsAndLabels/HandHeldPrint.vue';
import PrinterAlign from './components/SignsAndLabels/PrinterAlign.vue';
import LabelPrint from './components/SignsAndLabels/LabelPrint.vue';
import ActivateDeactivate from './components/SignsAndLabels/ActivateDeactivate.vue';
import IngredientPrint from './components/SignsAndLabels/IngredientPrint.vue';
import IngredientPrintStaging from './components/SignsAndLabels/Admin/IngredientPrintStaging.vue';
import ImportStoreSet from './components/SignsAndLabels/Admin/ImportStoreSet.vue';
import NewStoreSetPrint from './components/SignsAndLabels/Admin/NewStoreSetPrint.vue';
import AdUserRoles from './components/PogonaAdmin/AdUserRoles.vue';
import StoreLoginMappings from './components/PogonaAdmin/StoreLoginMappings.vue';
import StoreSetStagingPrint from './components/SignsAndLabels/Admin/StoreSetStagingPrint.vue';
import RenderingsProgress from './components/SignsAndLabels/RenderingsProgress.vue';
import CostAndMovement from './components/CostAndMovement/CostAndMovement.vue';
import NeighborArrivalNotificationEnabledStores from './components/PogonaAdmin/NeighborArrivalNotificationEnabledStores.vue';
import Deactivations from './components/SignsAndLabels/Admin/Deactivations.vue';
import StoreInfoBridge from './components/SignsAndLabels/Admin/StoreInfoBridge.vue';
import UsernameActivity from './components/PogonaAdmin/UsernameActivity.vue';
import AllowDeactivationItem from './components/SignsAndLabels/Admin/AllowDeactivationItem.vue';
import StoreSetAssignChanges from './components/SignsAndLabels/Admin/StoreSetAssignChanges.vue';
import AutoShipExceptions from './components/ecom/AutoShipExceptions.vue';
import CouponImageUpoad from './components/ecom/CouponImageUpload.vue';
import AutoShipPartnerFunding from './components/ecom/AutoShipPartnerFunding.vue';
import ProductXmlExport from './components/ecom/ProductXmlExport.vue';
import PclLanding from './components/Pcl/PclLanding.vue';
import PclNewStore from './components/Pcl/PclNewStore.vue';
import PclEditStore from './components/Pcl/PclEditStore.vue';
import DivisionRegionHome from './components/Pcl/DivisionRegionHome.vue';
import DivisionRegionEdit from './components/Pcl/DivisionRegionEdit.vue';
import PclStoreListingAll from './components/Pcl/PclStoreListingAll.vue';
import PclOwners from './components/Pcl/PclOwners.vue';
import PreCountReports from './components/Merchandising/PreCountReports.vue';
import D365DiscountWorksheet from './components/Merchandising/D365DiscountWorksheet.vue';
import StoreSetCompare from './components/SignsAndLabels/StoreSetCompare.vue';
import IgnoreItemsUpload from './components/ecom/IgnoreItemsUpload.vue';
import UnitOverride from './components/SignsAndLabels/UnitOverride.vue';
import NewItemRequestHome from './components/VendorPortal/NewItemRequestHome.vue';
import ItemUpdateHome from './components/VendorPortal/ItemUpdateHome.vue';
import UpcGenerator from './components/VendorPortal/UpcGenerator.vue';
import UploadItemUpdateView from './components/VendorPortal/UploadItemUpdateView.vue';
import ItemUpdateSearch from './components/VendorPortal/ItemUpdateSearch.vue';
import BatchSearch from './components/VendorPortal/BatchSearch.vue';
import HotDeals from './components/Merchandising/HotDeals.vue';
import InvoiceHome from './components/VendorPortal/InvoiceHome.vue';
import InvoiceDetail from './components/VendorPortal/InvoiceDetail.vue';
import InvoiceSearchResult from './components/VendorPortal/InvoiceSearchResult.vue';
import ManageD365Impersonation from './components/VendorPortal/ManageD365Impersonation.vue';
import UserVendorAccounts from './components/VendorPortal/UserVendorAccounts.vue';
import IgnoreItemManager from './components/ecom/IgnoreItemManager.vue';
import ManageNewItemTemplate from './components/VendorPortal/ManageNewItemTemplate.vue';
import POSearchHome from './components/VendorPortal/POSearchHome.vue';
import PODetail from './components/VendorPortal/PODetail.vue';
import KeyTracking from './components/KeyTracking/KeyTracking.vue';
import VendTransSearchHome from './components/VendorPortal/VendTransSearchHome.vue';
import VendTransPayRefDetail from './components/VendorPortal/VendTransPayRefDetail.vue';
import CustTransSearchHome from './components/VendorPortal/CustTransSearchHome.vue';
import CustTransPayRefDetail from './components/VendorPortal/CustTransPayRefDetail.vue';
import CustBalance from './components/VendorPortal/CustBalance.vue';
import VendBalance from './components/VendorPortal/VendBalance.vue';
import OsdHome from './components/Osd/OsdHome.vue';
import OsdEntryDetail from './components/Osd/OsdEntryDetail.vue';
import JournalList from './components/HandHeld/JournalList.vue';
import JournalDetail from './components/HandHeld/JournalDetail.vue';
import ContenthubSearch from './components/ecom/ContenthubSearch.vue';
import PrebookRequest from './components/PrebookPortal/PrebookRequest.vue';
import SeasonManagement from './components/PrebookPortal/SeasonManagement.vue';

const views = {
  Home,
  Logout,
  NotFound,
  RenderProgress,
  BatchCheck,
  StoreSetPrint,
  HandHeldPrint,
  PrinterAlign,
  LabelPrint,
  ActivateDeactivate,
  IngredientPrint,
  IngredientPrintStaging,
  ImportStoreSet,
  NewStoreSetPrint,
  AdUserRoles,
  StoreLoginMappings,
  StoreSetStagingPrint,
  RenderingsProgress,
  CostAndMovement,
  NeighborArrivalNotificationEnabledStores,
  Deactivations,
  StoreInfoBridge,
  UsernameActivity,
  AllowDeactivationItem,
  AutoShipExceptions,
  StoreSetAssignChanges,
  CouponImageUpoad,
  AutoShipPartnerFunding,
  ProductXmlExport,
  PclLanding,
  PclNewStore,
  PclEditStore,
  DivisionRegionHome,
  DivisionRegionEdit,
  PclStoreListingAll,
  PclOwners,
  PreCountReports,
  StoreSetCompare,
  IgnoreItemsUpload,
  D365DiscountWorksheet,
  UnitOverride,
  NewItemRequestHome,
  ItemUpdateHome,
  BatchSearch,
  UpcGenerator,
  UploadItemUpdateView,
  ItemUpdateSearch,
  HotDeals,
  InvoiceHome,
  InvoiceDetail,
  InvoiceSearchResult,
  ManageD365Impersonation,
  UserVendorAccounts,
  IgnoreItemManager,
  ManageNewItemTemplate,
  POSearchHome,
  PODetail,
  KeyTracking,
  VendTransSearchHome,
  VendTransPayRefDetail,
  CustTransSearchHome,
  CustTransPayRefDetail,
  CustBalance,
  VendBalance,
  OsdHome,
  OsdEntryDetail,
  JournalList,
  JournalDetail,
  ContenthubSearch,
  PrebookRequest,
  SeasonManagement,
};

export default views;
