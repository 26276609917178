import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { array_type, record_type, class_type, option_type, int32_type, string_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";

export class PogovProcessFile extends Record {
    constructor(ProcessedFileBlobPath, ProcessFileId, ProcessFileStatusDescription, ProcessFileStatusId, ProcessedProfileId, ProcessedProfileFriendlyName, ProcessingProfileFriendlyName, ProcessingProfileId, RecordsProcessed, SourceFileBlobPath, TimeInserted, TimeProcessed, UploadedBy, ProcessedRunId, EffectiveDate) {
        super();
        this.ProcessedFileBlobPath = ProcessedFileBlobPath;
        this.ProcessFileId = (ProcessFileId | 0);
        this.ProcessFileStatusDescription = ProcessFileStatusDescription;
        this.ProcessFileStatusId = (ProcessFileStatusId | 0);
        this.ProcessedProfileId = ProcessedProfileId;
        this.ProcessedProfileFriendlyName = ProcessedProfileFriendlyName;
        this.ProcessingProfileFriendlyName = ProcessingProfileFriendlyName;
        this.ProcessingProfileId = ProcessingProfileId;
        this.RecordsProcessed = (RecordsProcessed | 0);
        this.SourceFileBlobPath = SourceFileBlobPath;
        this.TimeInserted = TimeInserted;
        this.TimeProcessed = TimeProcessed;
        this.UploadedBy = UploadedBy;
        this.ProcessedRunId = ProcessedRunId;
        this.EffectiveDate = EffectiveDate;
    }
}

export function PogovProcessFile_$reflection() {
    return record_type("SearchItemUpdateFile.PogovProcessFile", [], PogovProcessFile, () => [["ProcessedFileBlobPath", string_type], ["ProcessFileId", int32_type], ["ProcessFileStatusDescription", string_type], ["ProcessFileStatusId", int32_type], ["ProcessedProfileId", option_type(int32_type)], ["ProcessedProfileFriendlyName", string_type], ["ProcessingProfileFriendlyName", string_type], ["ProcessingProfileId", option_type(int32_type)], ["RecordsProcessed", int32_type], ["SourceFileBlobPath", string_type], ["TimeInserted", class_type("System.DateTime")], ["TimeProcessed", option_type(class_type("System.DateTime"))], ["UploadedBy", string_type], ["ProcessedRunId", class_type("System.Guid")], ["EffectiveDate", option_type(class_type("System.DateTime"))]]);
}

export class ItemUpdateFileSearchResult extends Record {
    constructor(Results, TotalCount) {
        super();
        this.Results = Results;
        this.TotalCount = TotalCount;
    }
}

export function ItemUpdateFileSearchResult_$reflection() {
    return record_type("SearchItemUpdateFile.ItemUpdateFileSearchResult", [], ItemUpdateFileSearchResult, () => [["Results", option_type(array_type(PogovProcessFile_$reflection()))], ["TotalCount", option_type(int32_type)]]);
}

