<template>
  <v-row v-if="jspmOnline">
    <v-col>
      <v-dialog v-model="printerDialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            class="green mr-2"
            :disabled="!printAvailable || disabled"
            :loading="loading"
          >
            <v-icon>print</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Print</span>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-select
                v-model="selectedPrinter"
                :items="availablePrinters"
                label="Printer"
                @change="selectedPrinterChange"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="selectedTray"
                :items="availableTrays"
                item-text="printerTrayDescription"
                item-value="printerTrayId"
                :label="selectedTray ? 'Tray' : 'Tray (Automatic)'"
                @change="selectedTrayChange"
              ></v-select>
            </v-col>

            <v-row class="ml-1">
              <v-col cols="10">
                <v-menu
                  v-model="showPrintRangeSelector"
                  :close-on-content-click="false"
                  top
                  class="mt-0 pt-0"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="printRange"
                      label="Page Selection (e.g. 1,2,3,10-15)"
                      :error-messages="printRangeErrors"
                      @change="pageSelectionChange"
                      v-on="on"
                      v-bind="attrs"
                      autocomplete="off"
                    ></v-text-field>
                  </template>

                  <v-card max-height="300px">
                    <v-card-text class="white">
                      <v-checkbox
                        v-model="selectedPages"
                        v-for="page of numPagesItems"
                        :key="page"
                        :value="page"
                        :label="page.toString()"
                        autocomplete="off"
                        @change="selectedPagesChanged"
                        dense
                        class="print-range-selectors my-2 py-0"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  class="text--black"
                  v-model="selectedPagesAll"
                  @change="selectedPagesAllChange"
                  label="All"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="showPartialsCheckbox">
              <v-checkbox
                label="Fast printing for large files (experimental)"
                v-model="printPartials"
              />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="print" class="green" :disabled="!canPrint">
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn @click="printerDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        @click="downloadPdf"
        :disabled="downloading"
        :loading="downloading"
        title="Download print sheet"
      >
        <v-icon>cloud_download</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import * as JSPM from 'jsprintmanager-v4';
import { ContainerClient } from '@azure/storage-blob';
import { saveAs } from 'file-saver';
import MultiRange, { multirange } from 'multi-integer-range';
import * as zip from '@zip.js/zip.js';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdfUrl: {
      type: String,
      required: false,
    },
    partialUrls: {
      type: Array,
      required: false,
    },
    pdfFileName: {
      type: String,
      required: false,
    },
    isLandscape: {
      type: Boolean,
      required: false,
      default: false,
    },
    numPages: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPartialsCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      jspmOnline: false,
      availablePrinters: [],
      selectedPrinter: null,
      availableTrays: [],
      selectedTray: null,
      printerDialog: false,
      printRange: null,
      printRangeErrors: [],
      downloading: false,
      selectedPages: [],
      selectedPagesAll: true,
      showPrintRangeSelector: false,
      printPartials: false,
    };
  },
  computed: {
    printAvailable() {
      return (
        this.jspmOnline &&
        !!this.pdfUrl &&
        this.pdfUrl.length > 0 &&
        !!this.pdfFileName &&
        this.pdfFileName.length > 0
      );
    },
    canPrint() {
      return (
        this.jspmOnline &&
        !!this.selectedPrinter &&
        this.selectedPrinter.length > 0 &&
        !!this.pdfUrl &&
        this.pdfUrl.length > 0 &&
        !!this.pdfFileName &&
        this.pdfFileName.length > 0 &&
        this.selectedPages &&
        this.selectedPages.length > 0
      );
    },
    numPagesItems() {
      if (this.numPages && this.numPages > 0) {
        const pages = [];
        for (let pix = 0; pix < this.numPages; pix += 1) {
          pages.push(pix + 1);
        }
        return pages;
      }
      return [];
    },
    printRangeAll() {
      const numMin = Math.min(...this.numPagesItems);
      const numMax = Math.max(...this.numPagesItems);

      if (numMin === numMax) {
        return numMin.toString();
      }

      return `${numMin}-${numMax}`;
    },
  },
  async created() {
    // check to see if we have any available printers in the store
    // if we do, fill the printer info with that first
    if (
      this.$store.state.app.availablePrinters &&
      this.$store.state.app.availablePrinters.length > 0
    ) {
      this.availablePrinters = this.$store.state.app.availablePrinters;
      this.fillPrinters(this.$store.state.app.availablePrinters);
      this.jspmOnline = true;
      this.$emit('input', true);
    }

    window.zip = zip;

    if (this.$jsprintmanagerLicenseUrlV4) {
      JSPM.JSPrintManager.license_url = `${document.location.protocol}//${this.$apiBasePath}${this.$jsprintmanagerLicenseUrlV4}`;
    }
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = async () => {
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        this.availablePrinters = (await JSPM.JSPrintManager.getPrintersInfo())
          .map(x => ({
            name: x.name,
            trays: x.trays,
          }))
          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        this.$store.commit('app/availablePrinters', this.availablePrinters);

        this.fillPrinters(this.availablePrinters);
        this.jspmOnline = true;
        this.$emit('input', true);
      } else if (
        JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed ||
        JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked
      ) {
        this.jspmOnline = false;
        this.$emit('input', false);
        this.$emit('error-blocked');

        // if the user starts with "psp" we log that they don't have a working jsprintmanager instance
        this.trackMissingJsPrintManager();
      }
    };

    // ensure all pages are selected for the print range
    this.selectedPages = this.numPagesItems;
    this.printRange = this.printRangeAll;
  },
  methods: {
    trackMissingJsPrintManager() {
      try {
        if (this.username && this.username.startsWith('psp')) {
          this.$appInsights.trackEvent({
            name: 'jsprintmanager_missing',
            properties: { username: this.username },
          });
        }
      } catch (err) {
        this.$appInsights.trackException({
          exception: err,
          properties: {
            text: 'error trying to track jsprintmanager_missing',
            username: this.username,
            storeNumber: this.storeNumber,
            route: this.$route,
            id: '53d8e43b-0b09-432d-b340-e40843b25113',
          },
        });
      }
    },
    async downloadPdf() {
      this.downloading = true;
      try {
        const urlParts = this.pdfUrl.split('/');
        const containerClient = new ContainerClient(
          urlParts.slice(0, urlParts.length - 1).join('/'),
        );
        const blobClient = containerClient.getBlobClient(urlParts[urlParts.length - 1]);
        const download = await blobClient.download();
        const blobBody = await download.blobBody;

        // save the file to disk
        saveAs(blobBody, this.pdfFileName);

        this.downloading = false;
      } catch (e) {
        this.downloading = false;

        this.$appInsights.trackException({
          exception: e,
          properties: {
            text: 'Error downloading PDF',
            username: this.username,
            storeNumber: this.storeNumber,
            route: this.$route,
            id: '553ba926-6302-40ec-851a-9b35fce5a96c',
          },
        });
      }
    },
    fillPrinters(availablePrinters) {
      const selectedPrinterIx = availablePrinters
        .map(x => x.name)
        .indexOf(this.$store.state.app.selectedPrinter);

      const defaultPrinterIx =
        this.$defaultStorePrinterName && this.$defaultStorePrinterName.length > 0
          ? availablePrinters
              .map(x => x.name.toLowerCase())
              .indexOf(this.$defaultStorePrinterName.toLowerCase())
          : -1;

      // have they selected a printer from the dialog?
      if (this.$store.state.app.selectedPrinter && selectedPrinterIx > -1) {
        this.setPrinter(
          this.$store.state.app.selectedPrinter,
          this.$store.state.app.printerTray,
          availablePrinters,
          selectedPrinterIx,
        );
        // if they haven't selected one manually, use the configured default
      } else if (
        (!this.$store.state.app.selectedPrinter ||
          this.$store.state.app.selectedPrinter.length === 0) &&
        this.$defaultStorePrinterName &&
        this.$defaultStorePrinterName.length > 0 &&
        defaultPrinterIx > -1
      ) {
        this.setPrinter(
          availablePrinters[defaultPrinterIx].name,
          this.$defaultStorePrinterTrayName,
          availablePrinters,
          defaultPrinterIx,
        );
      }
    },
    setPrinter(printerName, trayName, availablePrinters, selectedPrinterIx) {
      this.selectedPrinter = printerName;
      this.$store.commit('app/selectedPrinter', this.selectedPrinter);
      this.fillTrays();

      const selectedTrayIx =
        trayName && trayName.length > 0
          ? availablePrinters[selectedPrinterIx].trays
              .map(x => x.toLowerCase())
              .indexOf(trayName.toLowerCase())
          : -1;

      if (selectedTrayIx > -1) {
        this.selectedTray = availablePrinters[selectedPrinterIx].trays[selectedTrayIx];
        this.$store.commit('app/printerTray', this.selectedTray);
      }
    },
    fillTrays() {
      if (this.selectedPrinter && this.selectedPrinter.length > 0) {
        const selectedPrinterIx = this.availablePrinters
          .map(x => x.name)
          .indexOf(this.selectedPrinter);

        if (selectedPrinterIx > -1) {
          this.availableTrays = this.availablePrinters[selectedPrinterIx].trays;
          this.selectedTray = null;
        }
      }
    },
    async selectedPrinterChange() {
      this.$store.commit('app/selectedPrinter', this.selectedPrinter);
      this.fillTrays();
    },
    selectedTrayChange() {
      this.$store.commit('app/printerTray', this.selectedTray);
    },
    print() {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
      cpj.clientPrinter.duplex = 1; /* Single side */

      if (this.selectedTray !== null) {
        const tray = this.availableTrays.filter(x => x === this.selectedTray);
        if (tray.length === 1) {
          cpj.clientPrinter.trayName = tray[0];
        }
      }

      const printFiles = [];
      if (
        this.showPartialsCheckbox === true &&
        this.printPartials === true &&
        !!this.partialUrls &&
        this.partialUrls.length > 1
      ) {
        printFiles.push(...this.partialUrls);
      } else {
        printFiles.push(this.pdfUrl);
      }

      let maxPagesPer = -1;
      if (
        this.printRange &&
        this.printRange.length > 0 &&
        this.printRangeErrors.length === 0 &&
        printFiles.length > 1
      ) {
        maxPagesPer = Math.ceil(this.numPagesItems.length / printFiles.length);
      }

      let printFileIx = 0;
      for (const printFile of printFiles) {
        try {
          const printFileParts = printFile.split('/');

          const myFile = new JSPM.PrintFilePDF(
            printFile,
            JSPM.FileSourceType.URL,
            printFileParts[printFileParts.length - 1],
            1,
          );

          myFile.printAutoCenter = true;

          // set print range, if set
          if (this.printRange && this.printRange.length > 0 && this.printRangeErrors.length === 0) {
            if (maxPagesPer > 0) {
              const partialPrintRange = [];
              // determine the page range for this partial file
              for (const ix in [...Array(maxPagesPer).keys()]) {
                // determine current page
                const realPage = Number(ix) + 1;
                const currentPage = realPage + printFileIx * maxPagesPer;

                // now check to see if it's included in the list of files
                if (this.selectedPages.indexOf(currentPage) > -1) {
                  partialPrintRange.push(realPage);
                }
              }

              if (partialPrintRange.length > 0) {
                const multiPrintRange = new MultiRange(partialPrintRange);
                myFile.printRange = multiPrintRange.toString();
              } else {
                // no pages selected, just break out
                continue;
              }
            } else {
              myFile.printRange = this.printRange;
            }
          }

          if (this.isLandscape) {
            myFile.printRotation = JSPM.PrintRotation.Rot270;
          }

          cpj.files.push(myFile);
        } finally {
          printFileIx += 1;
        }
      }

      const finished = () => {
        this.$emit('print-finished', printFiles);
      };
      //Handle print job events
      cpj.onFinished = () => {
        finished();
      };

      cpj.onUpdated = data => {
        // this means the user has canceled the print somehow without jsprintman
        if ((data && data.id === -1) || data?.result === 'ClientPrintJob done') {
          finished();
        }
      };

      cpj.sendToClient();

      this.printerDialog = false;
      this.$emit('printed', printFiles);
    },
    selectedPagesAllChange() {
      if (this.selectedPagesAll === true) {
        this.selectedPages = this.numPagesItems;
      } else {
        this.selectedPages = [];
      }
      this.selectedPagesChanged();
    },
    selectedPagesChanged() {
      this.selectedPagesAll = this.selectedPages.length >= this.numPagesItems.length;

      const selectedPagesOrdered = this.selectedPages.sort((a, b) => (a > b ? 1 : -1));
      if (this.selectedPages.length < this.numPagesItems.length) {
        const multiPrintRange = new MultiRange(selectedPagesOrdered);
        this.printRange = multiPrintRange.toString();
      } else if (this.selectedPages.length === this.numPagesItems.length) {
        this.printRange = this.printRangeAll;
      } else {
        this.printRange = '';
      }
    },
    pageSelectionChange() {
      if (this.printRange && this.printRange.length > 0) {
        const mrPrintRange = multirange(this.printRange);

        const expandedRange = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const range of mrPrintRange.getRanges()) {
          const rangeMin = Math.min(...range);
          const rangeMax = Math.max(...range);

          if (rangeMin === rangeMax) {
            expandedRange.push(rangeMin);
          } else {
            const rangeDiff = rangeMax - rangeMin;
            for (let rix = 0; rix <= rangeDiff; rix += 1) {
              expandedRange.push(rix + rangeMin);
            }
          }
        }
        this.selectedPages = expandedRange;
      }
    },
  },
  watch: {
    printRange: {
      // eslint-disable-next-line
      handler: function (val) {
        if (val && val.length > 0) {
          const trimmedVal = val.trim();
          const valid = /^\d{1,}((-\d{1,})|(,\d{1,})?){1,}$/g.exec(trimmedVal);

          this.printRangeErrors = [];
          if (!valid) {
            this.printRangeErrors.push('Invalid print range. Will print all pages.');
          }
        }
      },
    },
    pdfUrl: {
      // eslint-disable-next-line
      handler: function () {
        this.selectedPages = this.numPagesItems;
        this.printRange = this.printRangeAll;
      },
    },
  },
};
</script>

<style lang="scss">
.print-range-selectors .v-input__control .v-messages {
  display: none !important;
}
</style>
