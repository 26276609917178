import { Record, Union } from "../fable-library-js.4.24.0/Types.js";
import { record_type, string_type, bool_type, option_type, class_type, union_type } from "../fable-library-js.4.24.0/Reflection.js";
import { newGuid } from "../fable-library-js.4.24.0/Guid.js";
import { now } from "../fable-library-js.4.24.0/Date.js";

export class BatchType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NewItemBatch", "PackageChangeBatch"];
    }
}

export function BatchType_$reflection() {
    return union_type("pogona_vendor_domain.Batch.BatchType", [], BatchType, () => [[], []]);
}

export class Batch extends Record {
    constructor(BatchId, BatchType, Archived, FileName, CreatedBy, CreatedAt, LastModifiedBy, LastModifiedAt, CategoryManagerName) {
        super();
        this.BatchId = BatchId;
        this.BatchType = BatchType;
        this.Archived = Archived;
        this.FileName = FileName;
        this.CreatedBy = CreatedBy;
        this.CreatedAt = CreatedAt;
        this.LastModifiedBy = LastModifiedBy;
        this.LastModifiedAt = LastModifiedAt;
        this.CategoryManagerName = CategoryManagerName;
    }
}

export function Batch_$reflection() {
    return record_type("pogona_vendor_domain.Batch.Batch", [], Batch, () => [["BatchId", class_type("System.Guid")], ["BatchType", option_type(BatchType_$reflection())], ["Archived", bool_type], ["FileName", string_type], ["CreatedBy", string_type], ["CreatedAt", class_type("System.DateTime")], ["LastModifiedBy", string_type], ["LastModifiedAt", class_type("System.DateTime")], ["CategoryManagerName", option_type(string_type)]]);
}

export class ModifyData extends Record {
    constructor(ModifiedAt, ModifiedBy) {
        super();
        this.ModifiedAt = ModifiedAt;
        this.ModifiedBy = ModifiedBy;
    }
}

export function ModifyData_$reflection() {
    return record_type("pogona_vendor_domain.Batch.ModifyData", [], ModifyData, () => [["ModifiedAt", class_type("System.DateTime")], ["ModifiedBy", string_type]]);
}

export class BatchCommand extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CreateBatch", "ArchiveBatch", "UnArchiveBatch"];
    }
}

export function BatchCommand_$reflection() {
    return union_type("pogona_vendor_domain.Batch.BatchCommand", [], BatchCommand, () => [[["modifyData", ModifyData_$reflection()]], [["modifyData", ModifyData_$reflection()]], [["modifyData", ModifyData_$reflection()]]]);
}

export function initBatch() {
    return new Batch(newGuid(), undefined, false, "", "", now(), "", now(), undefined);
}

export function applyCreateData(modifyData, batch) {
    return new Batch(batch.BatchId, batch.BatchType, batch.Archived, batch.FileName, modifyData.ModifiedBy, modifyData.ModifiedAt, batch.LastModifiedBy, batch.LastModifiedAt, batch.CategoryManagerName);
}

export function applyModifyData(modifyData, batch) {
    return new Batch(batch.BatchId, batch.BatchType, batch.Archived, batch.FileName, batch.CreatedBy, batch.CreatedAt, modifyData.ModifiedBy, modifyData.ModifiedAt, batch.CategoryManagerName);
}

export function performCommand(command, batch) {
    switch (command.tag) {
        case 1:
            return applyModifyData(command.fields[0], new Batch(batch.BatchId, batch.BatchType, true, batch.FileName, batch.CreatedBy, batch.CreatedAt, batch.LastModifiedBy, batch.LastModifiedAt, batch.CategoryManagerName));
        case 2:
            return applyModifyData(command.fields[0], new Batch(batch.BatchId, batch.BatchType, false, batch.FileName, batch.CreatedBy, batch.CreatedAt, batch.LastModifiedBy, batch.LastModifiedAt, batch.CategoryManagerName));
        default: {
            const modifyData = command.fields[0];
            return applyModifyData(modifyData, applyCreateData(modifyData, batch));
        }
    }
}

