import { Record, Union } from "../../fable-library-js.4.24.0/Types.js";
import { record_type, option_type, list_type, int32_type, class_type, string_type, union_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookItem_$reflection } from "./PrebookItem.fs.js";
import { PrebookItemError_$reflection } from "./PrebookItemError.fs.js";
import { isNullOrWhiteSpace } from "../../fable-library-js.4.24.0/String.js";
import { isEmpty, append, empty, singleton } from "../../fable-library-js.4.24.0/List.js";
import { newGuid } from "../../fable-library-js.4.24.0/Guid.js";
import { map, delay, toArray } from "../../fable-library-js.4.24.0/Seq.js";
import { rangeDouble } from "../../fable-library-js.4.24.0/Range.js";

export class Month extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
}

export function Month_$reflection() {
    return union_type("pogona_vendor_domain.prebook.Month", [], Month, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export class PrebookMonthSeason extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Month", "Season"];
    }
}

export function PrebookMonthSeason_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookMonthSeason", [], PrebookMonthSeason, () => [[["month", Month_$reflection()]], [["season", string_type]]]);
}

export class PrebookRequestStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PrebookInProgress", "PrebookSubmitForReview", "PrebookRejected", "PrebookMerchApproved"];
    }
}

export function PrebookRequestStatus_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookRequestStatus", [], PrebookRequestStatus, () => [[], [], [["reason", string_type]], []]);
}

export class PrebookRequestError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PrebookRequestTestError", "PrebookCategoryManagerRequired", "PrebookCreatedByRequired"];
    }
}

export function PrebookRequestError_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookRequestError", [], PrebookRequestError, () => [[], [], []]);
}

export class PrebookRequest extends Record {
    constructor(id, CategoryManager, MonthSeason, Year, CreatedBy, CreatedDate, PreboookErrors, Items, ItemErrors, ExcelPath, Status) {
        super();
        this.id = id;
        this.CategoryManager = CategoryManager;
        this.MonthSeason = MonthSeason;
        this.Year = (Year | 0);
        this.CreatedBy = CreatedBy;
        this.CreatedDate = CreatedDate;
        this.PreboookErrors = PreboookErrors;
        this.Items = Items;
        this.ItemErrors = ItemErrors;
        this.ExcelPath = ExcelPath;
        this.Status = Status;
    }
}

export function PrebookRequest_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookRequest", [], PrebookRequest, () => [["id", class_type("System.Guid")], ["CategoryManager", string_type], ["MonthSeason", PrebookMonthSeason_$reflection()], ["Year", int32_type], ["CreatedBy", string_type], ["CreatedDate", class_type("System.DateTime")], ["PreboookErrors", list_type(PrebookRequestError_$reflection())], ["Items", list_type(PrebookItem_$reflection())], ["ItemErrors", list_type(PrebookItemError_$reflection())], ["ExcelPath", option_type(string_type)], ["Status", PrebookRequestStatus_$reflection()]]);
}

export function PrebookRequestFunctions_categoryManagerRequired(request) {
    if (isNullOrWhiteSpace(request.CategoryManager)) {
        return singleton(new PrebookRequestError(1, []));
    }
    else {
        return empty();
    }
}

export function PrebookRequestFunctions_createdByRequired(request) {
    if (isNullOrWhiteSpace(request.CreatedBy)) {
        return singleton(new PrebookRequestError(2, []));
    }
    else {
        return empty();
    }
}

export function PrebookRequestFunctions_validatePrebookRequest(request) {
    return append(PrebookRequestFunctions_categoryManagerRequired(request), PrebookRequestFunctions_createdByRequired(request));
}

export function PrebookRequestFunctions_initNewPrebookRequest(categoryManager, monthSeason, year, createdBy, createdDate) {
    return new PrebookRequest(newGuid(), categoryManager, monthSeason, year, createdBy, createdDate, empty(), empty(), empty(), undefined, new PrebookRequestStatus(0, []));
}

export function PrebookRequestFunctions_yearsToDisplay(curentYear) {
    return toArray(delay(() => map((year) => year, rangeDouble(curentYear, 1, curentYear + 1))));
}

export function PrebookRequestFunctions_monthToString(month) {
    switch (month.tag) {
        case 1:
            return "February";
        case 2:
            return "March";
        case 3:
            return "April";
        case 4:
            return "May";
        case 5:
            return "June";
        case 6:
            return "July";
        case 7:
            return "August";
        case 8:
            return "September";
        case 9:
            return "October";
        case 10:
            return "November";
        case 11:
            return "December";
        default:
            return "January";
    }
}

export function PrebookRequestFunctions_monthsToArrayTextValue() {
    return [{
        Text: PrebookRequestFunctions_monthToString(new Month(0, [])),
        Value: new PrebookMonthSeason(0, [new Month(0, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(1, [])),
        Value: new PrebookMonthSeason(0, [new Month(1, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(2, [])),
        Value: new PrebookMonthSeason(0, [new Month(2, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(3, [])),
        Value: new PrebookMonthSeason(0, [new Month(3, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(4, [])),
        Value: new PrebookMonthSeason(0, [new Month(4, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(5, [])),
        Value: new PrebookMonthSeason(0, [new Month(5, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(6, [])),
        Value: new PrebookMonthSeason(0, [new Month(6, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(7, [])),
        Value: new PrebookMonthSeason(0, [new Month(7, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(8, [])),
        Value: new PrebookMonthSeason(0, [new Month(8, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(9, [])),
        Value: new PrebookMonthSeason(0, [new Month(9, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(10, [])),
        Value: new PrebookMonthSeason(0, [new Month(10, [])]),
    }, {
        Text: PrebookRequestFunctions_monthToString(new Month(11, [])),
        Value: new PrebookMonthSeason(0, [new Month(11, [])]),
    }];
}

export function PrebookRequestFunctions_prebookHasErrors(request) {
    if (!isEmpty(request.PreboookErrors)) {
        return true;
    }
    else {
        return !isEmpty(request.ItemErrors);
    }
}

export function PrebookRequestFunctions_isPrebookMonth(monthSeason) {
    if (monthSeason.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function PrebookRequestFunctions_isPrebookSeason(monthSeason) {
    if (monthSeason.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

