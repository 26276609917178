import { Record } from "../../fable-library-js.4.24.0/Types.js";
import { record_type, int32_type } from "../../fable-library-js.4.24.0/Reflection.js";

export class PrebookItemD365Data extends Record {
    constructor(wip) {
        super();
        this.wip = (wip | 0);
    }
}

export function PrebookItemD365Data_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookItemD365Data", [], PrebookItemD365Data, () => [["wip", int32_type]]);
}

