import { Record } from "../fable-library-js.4.24.0/Types.js";
import { record_type, option_type, unit_type, lambda_type, class_type, union_type, string_type } from "../fable-library-js.4.24.0/Reflection.js";
import { FSharpResult$2 } from "../fable-library-js.4.24.0/Result.js";
import { PrebookCommandResult_$reflection, PrebookCommand_$reflection } from "../pogona_vendor_domain.fable.1.153.0/prebook/PrebookCommands.fs.js";
import { PrebookRequest_$reflection } from "../pogona_vendor_domain.fable.1.153.0/prebook/PrebookRequest.fs.js";
import { SeasonLookup_$reflection } from "../pogona_vendor_domain.fable.1.153.0/prebook/PrebookSeasonLookup.fs.js";

export class IPrebookApi extends Record {
    constructor(test, processPrebookCommand, getPrebookRequest, saveSeasonLookup, getSeasonLookup) {
        super();
        this.test = test;
        this.processPrebookCommand = processPrebookCommand;
        this.getPrebookRequest = getPrebookRequest;
        this.saveSeasonLookup = saveSeasonLookup;
        this.getSeasonLookup = getSeasonLookup;
    }
}

export function IPrebookApi_$reflection() {
    return record_type("pogona_vendor_api_lib.IPrebookApi.IPrebookApi", [], IPrebookApi, () => [["test", class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]])])], ["processPrebookCommand", lambda_type(PrebookCommand_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [PrebookCommandResult_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", PrebookCommandResult_$reflection()]], [["ErrorValue", string_type]]])]))], ["getPrebookRequest", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [PrebookRequest_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", PrebookRequest_$reflection()]], [["ErrorValue", string_type]]])]))], ["saveSeasonLookup", lambda_type(SeasonLookup_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]))], ["getSeasonLookup", class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SeasonLookup_$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", option_type(SeasonLookup_$reflection())]], [["ErrorValue", string_type]]])])]]);
}

