import { Record } from "../../fable-library-js.4.24.0/Types.js";
import { record_type, option_type, int32_type, class_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookItemD365Data_$reflection } from "./PrebookItemD365Data.fs.js";

export class PrebookItem extends Record {
    constructor(id, LineNumber, D365Data) {
        super();
        this.id = id;
        this.LineNumber = (LineNumber | 0);
        this.D365Data = D365Data;
    }
}

export function PrebookItem_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookItem", [], PrebookItem, () => [["id", class_type("System.Guid")], ["LineNumber", int32_type], ["D365Data", option_type(PrebookItemD365Data_$reflection())]]);
}

