import { NewItemRequestCommand, CreateNewItemRequestData, CategoryData, PetPartnerData, BarCodeData, NewItemRequestType, PartPartnerItemData, DistributionNetwork, ProductData, UOM, UnitOrCase, NewItemRequestStatus } from "../fable_modules/pogona_vendor_domain.fable.1.153.0/NewItemRequest.fs.js";
import { tryParse, minValue, now } from "../fable_modules/fable-library-js.4.24.0/Date.js";
import { EventMetaData } from "../fable_modules/pogona_vendor_domain.fable.1.153.0/VendorDomain.fs.js";
import { FSharpRef } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { singleton } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { NewItemRequestCommands } from "../fable_modules/pogona_vendor_api_lib.fable.1.507.0/INewItemRequestApi.fs.js";

export function mapStatus(status) {
    switch (status) {
        case "InProgress":
            return new NewItemRequestStatus(0, []);
        case "PendingReview":
            return new NewItemRequestStatus(1, []);
        case "Accepted":
            return new NewItemRequestStatus(2, []);
        case "Rejected":
            return new NewItemRequestStatus(3, []);
        default:
            return undefined;
    }
}

export function mapUnitOrCase(uc) {
    switch (uc) {
        case "Unit":
            return new UnitOrCase(0, []);
        case "Case":
            return new UnitOrCase(1, []);
        default:
            return undefined;
    }
}

export function mapUOM(uom) {
    switch (uom) {
        case "CI":
            return new UOM(0, []);
        case "CT":
            return new UOM(1, []);
        case "EA":
            return new UOM(2, []);
        case "FT":
            return new UOM(3, []);
        case "GL":
            return new UOM(4, []);
        case "GM":
            return new UOM(5, []);
        case "LB":
            return new UOM(6, []);
        case "LT":
            return new UOM(7, []);
        case "OZ":
            return new UOM(8, []);
        case "ML":
            return new UOM(9, []);
        case "QT":
            return new UOM(10, []);
        default:
            return undefined;
    }
}

export function copyToProductData(data) {
    return new ProductData(data.ProductName, data.RetailPackageSize, mapUOM(data.RetailPackageUOM));
}

export function mapDistributionNetwork(network) {
    switch (network) {
        case "PSPD":
            return new DistributionNetwork(0, []);
        case "DSD":
            return new DistributionNetwork(1, []);
        default:
            return undefined;
    }
}

export function copyToItemData(request) {
    const ManufacturerAccountNumber = request.ManufacturerAccountNumber;
    const Brand = request.Brand;
    const VendorId = request.VendorId;
    const ExternalItemNumber = request.ExternalItemNumber;
    const MasterCase = request.MasterCase | 0;
    const InnerCasePack = request.InnerCasePack | 0;
    const LayerQuantity = request.LayerQuantity | 0;
    const PalletQuantity = request.PalletQuantity | 0;
    const DirectToStore = mapUnitOrCase(request.DirectToStore);
    return new PartPartnerItemData(ManufacturerAccountNumber, Brand, VendorId, ExternalItemNumber, MasterCase, InnerCasePack, LayerQuantity, PalletQuantity, mapDistributionNetwork(request.DistributionNetwork), DirectToStore, request.DirectToStoreOrVendorIntoPspdPurchaseCost, request.MfgSuggestedRetail, request.MapPrice, request.IMap, request.MaterialSafetyDataSheetNeeded, request.MinimumOrderQuantity, request.UnitWeight, mapUOM(request.UnitWeightUOM), request.UnitHeight, request.UnitWidth, request.UnitDepth, request.CaseHeight, request.CaseWidth, request.CaseDepth, request.ProductAttribute);
}

export function copyToPetPartnerData(request) {
    return new PetPartnerData(new NewItemRequestType(0, []), new BarCodeData(request.FullUnitBarcode, request.CaseBarcode, request.OtherBarcode), copyToProductData(request), copyToItemData(request));
}

export function copyToCategoryData(request) {
    const VendorNumber = request.VendorNumber;
    const ShipsToStores = mapUnitOrCase(request.ShipsToStores);
    const ShipUnitCostFromPspd = request.ShipUnitCostFromPspd;
    const RetailBasePrice = request.RetailBasePrice;
    const RetailHighPrice = request.RetailHighPrice;
    const Hierarchy = request.Hierarchy;
    const CategoryManagerName = request.CategoryManagerName;
    const PspdInventoryManagerName = request.PspdInventoryManagerName;
    const Reason_1 = request.Reason;
    const Season = request.Season;
    return new CategoryData(VendorNumber, ShipsToStores, ShipUnitCostFromPspd, RetailBasePrice, RetailHighPrice, Hierarchy, "", CategoryManagerName, PspdInventoryManagerName, undefined, Reason_1, request.Seasonal, Season, request.Year, request.Category);
}

export function createItemUpdateCommand(data) {
    let matchValue, matchValue_1, outArg;
    return new NewItemRequestCommands(data.AggregateId, singleton(new NewItemRequestCommand(0, [[new EventMetaData("", now()), new CreateNewItemRequestData(data.AggregateId, data.RequestID, data.FullUnitBarcode, data.ProductName, (matchValue = mapStatus(data.Status), (matchValue == null) ? (() => {
        throw new Error(`Mapping of status failed ${data.Status}`);
    })() : matchValue), "", new NewItemRequestType(1, []), undefined, copyToPetPartnerData(data), undefined, undefined, undefined, copyToCategoryData(data), undefined, data.IsMulti, data.IsLast, data.BatchId, data.NewUPC, (matchValue_1 = ((outArg = minValue(), [tryParse(data.AnticipatedDateOfItemChange, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg])), matchValue_1[0] ? matchValue_1[1] : undefined))]])));
}

