import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { bool_type, record_type, int32_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";

export class ProfileUpdateModel extends Record {
    constructor(ProcessingProfileId) {
        super();
        this.ProcessingProfileId = (ProcessingProfileId | 0);
    }
}

export function ProfileUpdateModel_$reflection() {
    return record_type("ProfileUpdateModel.ProfileUpdateModel", [], ProfileUpdateModel, () => [["ProcessingProfileId", int32_type]]);
}

export class ArchiveUpdateModel extends Record {
    constructor(Archived) {
        super();
        this.Archived = Archived;
    }
}

export function ArchiveUpdateModel_$reflection() {
    return record_type("ProfileUpdateModel.ArchiveUpdateModel", [], ArchiveUpdateModel, () => [["Archived", bool_type]]);
}

