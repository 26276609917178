/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Store } from 'vuex';
import { PspEmployee } from './VendorDomainTypes';

export interface AllStores {
  app: {
    showStoreNumber: boolean;
    storeNumber: string;
    username: string;
  };
  vendorportal: {
    categoryManagers: PspEmployee[] | null;
  };
}
export function formatApp(target: string): string {
  return `app/${target}`;
}
export function dispatchApp(store: Store<AllStores>, action: string, payload: any): Promise<any> {
  return store.dispatch(formatApp(action), payload);
}
export function commitApp(store: Store<AllStores>, mutation: string, payload: any): void {
  store.commit(formatApp(mutation), payload);
}
export function formatVendorPortal(target: string): string {
  return `vendorportal/${target}`;
}
export function dispatchVendorPortal(
  store: Store<AllStores>,
  action: string,
  payload: any,
): Promise<any> {
  return store.dispatch(formatVendorPortal(action), payload);
}
export function commitVendorPortal(store: Store<AllStores>, mutation: string, payload: any): void {
  store.commit(formatVendorPortal(mutation), payload);
}
