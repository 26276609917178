import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as domain from '@psp/pogona_vendor_domain';
import * as prebookClient from '@/utils/PrebookApiClient.fs.js';
import * as prebookApi from '@psp/pogona_vendor_api_lib';
import { VendorPortalFinanceInitArgs } from '@/utils/VendorPortalFinanceInitArgs';
import { ClientAuthenticatedAdalApiClient } from '@psp/pogona_adal_js';
import store from '@/store';

function constructBaseUrl(initArgs: VendorPortalFinanceInitArgs): string {
  return initArgs.OverrideUrl.length > 0 ? initArgs.OverrideUrl : initArgs.Origin;
}

export function getPrebookClientOrThrow(
  getClient: (() => Promise<prebookApi.IPrebookApi>) | null,
): Promise<prebookApi.IPrebookApi> {
  if (getClient) return getClient();
  else throw new Error('Prebook client not available');
}

export const usePrebookStore = defineStore('prebookstore', () => {
  const prebookRequest = ref<domain.PrebookRequest | null>(null);
  const authClient = ref<ClientAuthenticatedAdalApiClient | null>(null);
  const getPrebookClient = ref<(() => Promise<prebookApi.IPrebookApi>) | null>(null);
  const seasonLookup = ref<domain.SeasonLookup | null>(null);

  function init(args: VendorPortalFinanceInitArgs) {
    authClient.value = args.AuthClient;
    const getToken = async (client: ClientAuthenticatedAdalApiClient) => {
      return client.client.getToken() as Promise<string>;
    };
    const baseUrl = constructBaseUrl(args);
    getPrebookClient.value = async () => {
      return prebookClient.prebookApiClient(baseUrl, () => getToken(authClient.value));
    };
  }
  async function savePrebookRequest(prebookRequest: domain.PrebookRequest) {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      await prebookClient.savePrebookRequest(prebookRequest, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error saving prebook request error: ${JSON.stringify(error)}`,
        id: '4e814f94-86b8-4ab9-9258-b9d8f8a52e8f',
      });
      throw error;
    }
  }
  async function getPrebookRequest(prebookRequestId: string): Promise<domain.PrebookRequest> {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      const prebook = await prebookClient.getPrebookRequest(prebookRequestId, client);
      prebookRequest.value = prebook;
      return prebook;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting prebook request error: ${JSON.stringify(error)}`,
        id: 'd3a8dc23-1ab9-4ff1-b7d5-d16e2fe3f354',
      });
      throw error;
    }
  }
  async function saveSeasonLookup(seasonLookup: domain.SeasonLookup) {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      await prebookClient.saveSeasonLookup(seasonLookup, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error saving season lookup error: ${JSON.stringify(error)}`,
        id: '76693cb5-02b0-4fdf-a414-32890ca77db9',
      });
      throw error;
    }
  }
  async function getSeasonLookup(): Promise<domain.SeasonLookup> {
    try {
      const client = await getPrebookClientOrThrow(getPrebookClient.value);
      const season = await prebookClient.getSeasonLookup(client);
      seasonLookup.value = season;
      return season;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting season lookup error: ${JSON.stringify(error)}`,
        id: '9d587ebe-a1ab-4001-a7dc-8c6246813ffa',
      });
      throw error;
    }
  }
  return {
    init,
    savePrebookRequest,
    getPrebookRequest,
    saveSeasonLookup,
    getSeasonLookup,
    prebookRequest,
    seasonLookup,
  };
});
