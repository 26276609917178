import { defineStore } from 'pinia';
import { ref } from 'vue';
import { PogonaApiClient } from '@psp/pogona_api_client_js';
import store from '@/store';
import ODataProps from '@/utils/ODataProps';
import buildQuery from 'odata-query';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import uuid from 'uuid';
import FileSaver from 'file-saver';
import dateFormat from 'dateformat';

export class IgnoreItem {
  constructor(
    public Comments: string | null = null,
    public Description: string | null = null,
    public IgnoreItemId: string = '',
    public ItemId: string = '',
    public Upc: string = '',
  ) {}
}

export class ContentHubIgnoreItemResult {
  constructor(
    public ItemId: string = '',
    public Upc: string = '',
    public Id: string | null = null,
    public Success: boolean = true,
    public ErrorMessage: string | null = '',
    public Description: string | null = null,
  ) {}
}

export class ChubProductSearchParams {
  constructor(
    public Take: number = 50,
    public Skip: number = 0,
    public Search: string | null = '',
    public PCMProductStatusToProduct: PCMProductStatusToProduct[] = [],
    public ExcludedFromWebsiteToProduct: boolean[] = [],
    public InSet: boolean[] = [],
  ) {}
}

export class ChubProductSearchResult {
  constructor(
    public ChId: number = 0,
    public WebId: number = 0,
    public Identifier: string | null = null,
    public ItemId: string | null = null,
    public PrimaryUpc: string | null = null,
    public ProductName: string | null = null,
    public PCMProductStatusToProduct: PCMProductStatusToProduct[] = [],
    public PCMProductStatusToProductRaw: string | null = null,
    public ExcludedFromWebsiteToProduct: boolean[] = [],
    public InSet: boolean[] = [],
    public InStock: number = 0,
  ) {}
}

export class ChubProductSearchPayload {
  constructor(
    public Results: ChubProductSearchResult[] = [],
    public TotalCount: number = 0,
  ) {}
}

export enum PCMProductStatusToProduct {
  MerchApproved = 1,
  ECommerceReview = 2,
  ServicesApproved = 3,
  Approved = 4,
  Created = 5,
  Archived = 6,
  Draft = 7,
}

export const useEcomStore = defineStore('ecomStore', () => {
  const client = ref<PogonaApiClient | null>(null);
  const ignoredItems = ref<IgnoreItem[]>([] as IgnoreItem[]);
  const ignoredItemsCount = ref(0);
  const odataProps = ref<ODataProps>(new ODataProps());
  const gettingIgnoredItems = ref(false);
  const gettingIgnoredItemsCount = ref(false);
  const updatingIgnoreItems = ref(false);
  const ignoreItemResults = ref<ContentHubIgnoreItemResult[]>([] as ContentHubIgnoreItemResult[]);
  const progressId = ref('');
  const progressDescription = ref('');
  const exportingIgnoredItems = ref(false);
  const chProductSearchPayload = ref<ChubProductSearchPayload>();
  const chProductSearchParams = ref<ChubProductSearchParams>(new ChubProductSearchParams());
  const chProductSearchItems = ref<ChubProductSearchResult[]>();
  const searchingContentHub = ref(false);
  const exportingContentHub = ref(false);

  const pcmProductStatusesToProduct = ref<any>([
    {
      text: 'Merch Approved',
      value: PCMProductStatusToProduct.MerchApproved,
    },
    {
      text: 'ECommerce Review',
      value: PCMProductStatusToProduct.ECommerceReview,
    },
    {
      text: 'Services Approved',
      value: PCMProductStatusToProduct.ServicesApproved,
    },
    {
      text: 'Approved',
      value: PCMProductStatusToProduct.Approved,
    },
    {
      text: 'Created',
      value: PCMProductStatusToProduct.Created,
    },
    {
      text: 'Archived',
      value: PCMProductStatusToProduct.Archived,
    },
    {
      text: 'Draft',
      value: PCMProductStatusToProduct.Draft,
    },
  ]);

  function init(http: any, baseUrl: string | null): void {
    client.value = new PogonaApiClient(null, http, baseUrl);
  }

  function getIgnoredItems(): Promise<any> {
    gettingIgnoredItems.value = true;
    const odataStr = buildQuery(odataProps.value).substring(1);
    return client
      .value!.apiGet(`./ecom2/ignoreitem?${odataStr}`)
      .then(x => {
        if (odataProps.value.skip > 0) {
          ignoredItems.value.push(...x.data);
        } else {
          ignoredItems.value = x.data;
        }
        gettingIgnoredItems.value = false;
      })
      .catch(err => {
        gettingIgnoredItems.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error getting ignored items',
          id: '9245a188-9e4a-4222-b328-84bfb16a58de',
        });
        throw err;
      });
  }

  function getIgnoredItemsCount(): Promise<any> {
    gettingIgnoredItemsCount.value = true;
    const odataStr = buildQuery(odataProps.value).substring(1);

    return client
      .value!.apiGet(`./ecom2/ignoreitem/$count?${odataStr}`)
      .then(x => {
        ignoredItemsCount.value = x.data;
        gettingIgnoredItemsCount.value = false;
      })
      .catch(err => {
        gettingIgnoredItemsCount.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error getting ignored items count',
          id: '7a968b77-6b5e-4701-b57b-fa7bbd787760',
        });
        throw err;
      });
  }

  async function deleteIgnoredItems(items: IgnoreItem[], apiBasePath: string, adalToken: string) {
    return new Promise((resolve, reject) => {
      const errorMessage = error => {
        store.commit('setError', {
          err: error,
          text: 'Error deleting ignored item(s)',
          id: '6d306bd2-1cee-4a3c-b04b-bb9199e512ce',
        });
        reject(error);
      };

      try {
        updatingIgnoreItems.value = true;
        ignoreItemResults.value = [] as ContentHubIgnoreItemResult[];

        const id = uuid();
        progressId.value = uuid();
        progressDescription.value = 'Deleting ignored item(s)';
        let created = false;

        const onClose = () => {
          // If the web socket connection closed before
          // the batch was created throw an error message.
          if (created === false) {
            errorMessage(null);
          }
        };

        // Register on message handler before posting the message
        const onmessage = event => {
          const recMessage = JSON.parse(event.data);

          created = true;

          ws?.close();

          const resultsTyped = [] as ContentHubIgnoreItemResult[];
          if (recMessage.results) {
            for (const result of recMessage.results) {
              resultsTyped.push(
                new ContentHubIgnoreItemResult(
                  result.itemId,
                  result.upc,
                  result.id,
                  result.success,
                  result.errorMessage,
                  result.description,
                ),
              );
            }
          }

          ignoreItemResults.value = recMessage.results
            ? resultsTyped
            : ([] as ContentHubIgnoreItemResult[]);
          updatingIgnoreItems.value = false;
          resolve(recMessage);
        };

        const onOpen = async () => {
          const deleteIgnoreItemsData = {
            id,
            items,
            progressId: progressId.value,
          };

          await client.value!.apiPost(
            './servicebus/contenthub/delete-ignore-items',
            deleteIgnoreItemsData,
          );
        };

        const ws = new PogonaWebSocket(
          apiBasePath,
          'ch_delete_ignore_items',
          id,
          adalToken,
          onClose,
          onmessage,
          600000,
          onOpen,
        );
      } catch (err) {
        errorMessage(err);
      }
    });
  }

  function createIgnoredItem(
    item: IgnoreItem,
    apiBasePath: string,
    adalToken: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorMessage = err => {
        store.commit('setError', {
          err: err,
          text: 'Error creating ignored item',
          id: '9fd846a7-e704-4929-871b-148523e79bb2',
        });
      };

      try {
        updatingIgnoreItems.value = true;
        ignoreItemResults.value = [] as ContentHubIgnoreItemResult[];

        const id = uuid();
        progressId.value = uuid();
        progressDescription.value = 'Creating ignored item';
        let created = false;

        const onClose = () => {
          // If the web socket connection closed before
          // the batch was created throw an error message.
          if (created === false) {
            errorMessage(null);
          }
        };

        // Register on message handler before posting the message
        const onmessage = event => {
          const recMessage = JSON.parse(event.data);

          created = true;

          ws?.close();

          const resultsTyped = [] as ContentHubIgnoreItemResult[];
          if (recMessage.results) {
            resultsTyped.push(
              new ContentHubIgnoreItemResult(
                recMessage.results.itemId,
                recMessage.results.upc,
                recMessage.results.id,
                recMessage.results.success,
                recMessage.results.errorMessage,
                recMessage.results.description,
              ),
            );
          }

          ignoreItemResults.value = recMessage.results
            ? resultsTyped
            : ([] as ContentHubIgnoreItemResult[]);
          updatingIgnoreItems.value = false;
          resolve(recMessage);
        };

        const onOpen = async () => {
          const createIgnoreItemsData = {
            id,
            item,
            progressId: progressId.value,
          };

          await client.value!.apiPost(
            './servicebus/contenthub/create-ignore-item',
            createIgnoreItemsData,
          );
        };

        const ws = new PogonaWebSocket(
          apiBasePath,
          'ch_create_ignore_item',
          id,
          adalToken,
          onClose,
          onmessage,
          600000,
          onOpen,
        );
      } catch (err) {
        updatingIgnoreItems.value = false;
        errorMessage(err);
        reject(err);
      }
    });
  }

  async function createIgnoredItems(
    file: File,
    comments: string,
    apiBasePath: string,
    adalToken: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorMessage = err => {
        store.commit('setError', {
          err: err,
          text: 'Error creating ignored item',
          id: '9fd846a7-e704-4929-871b-148523e79bb2',
        });
      };

      try {
        updatingIgnoreItems.value = true;
        ignoreItemResults.value = [] as ContentHubIgnoreItemResult[];

        const formData = new FormData();
        formData.append('file', file[0], file[0].name);
        client.value!.apiPost(`./ecom2/contenthub/uploadfile`, formData).then(blobName => {
          ignoreItemResults.value = [] as ContentHubIgnoreItemResult[];

          const id = uuid();
          progressId.value = uuid();
          progressDescription.value = 'Processing ignore items file';
          let created = false;

          const onClose = () => {
            // If the web socket connection closed before
            // the batch was created throw an error message.
            if (created === false) {
              errorMessage(null);
            }
          };

          // Register on message handler before posting the message
          const onmessage = event => {
            const recMessage = JSON.parse(event.data);

            created = true;

            ws?.close();

            const resultsTyped = [] as ContentHubIgnoreItemResult[];
            if (recMessage.results) {
              for (const result of recMessage.results) {
                resultsTyped.push(
                  new ContentHubIgnoreItemResult(
                    result.itemId,
                    result.upc,
                    result.id,
                    result.success,
                    result.errorMessage,
                    result.description,
                  ),
                );
              }
            }

            ignoreItemResults.value = recMessage.results
              ? resultsTyped
              : ([] as ContentHubIgnoreItemResult[]);
            updatingIgnoreItems.value = false;
            resolve(recMessage);
          };

          const onOpen = async () => {
            const processIgnoreFileData = {
              id,
              blobLocation: blobName.data,
              comments,
              progressId: progressId.value,
            };

            await client.value!.apiPost(
              './servicebus/contenthub/process-ignore-items',
              processIgnoreFileData,
            );
          };

          const ws = new PogonaWebSocket(
            apiBasePath,
            'ch_process_ignore_item_file',
            id,
            adalToken,
            onClose,
            onmessage,
            600000,
            onOpen,
          );

          updatingIgnoreItems.value = false;
        });
      } catch (err) {
        updatingIgnoreItems.value = false;
        errorMessage(err);
        reject(err);
      }
    });
  }

  function exportIgnoredItems() {
    exportingIgnoredItems.value = true;
    return client
      .value!.apiGet('./ecom2/contenthub/export', null, null, {
        responseType: 'blob',
      })
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `exclusion-items-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
        exportingIgnoredItems.value = false;
      })
      .catch(err => {
        exportingIgnoredItems.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error exporting ignored items',
          id: '8cbd36af-5240-488e-8420-219a0971d93a',
        });
        throw err;
      });
  }

  function searchContentHub(options: any): Promise<any> {
    searchingContentHub.value = true;

    let sortBy = getSortByForContentHubSearch(options);

    return client
      .value!.apiPost(
        `./ecom2/contenthub/search?$skip=${chProductSearchParams.value.Skip}&$top=${chProductSearchParams.value.Take}${sortBy}`,
        chProductSearchParams.value,
      )
      .then(x => {
        chProductSearchPayload.value = x.data;
        if (chProductSearchParams.value.Skip ?? 0 > 0) {
          chProductSearchItems.value?.push(...(chProductSearchPayload.value?.Results ?? []));
        } else {
          chProductSearchItems.value = chProductSearchPayload.value?.Results;
        }
        chProductSearchParams.value.Skip += chProductSearchParams.value.Take;
        searchingContentHub.value = false;
      })
      .catch(err => {
        searchingContentHub.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error searching Content Hub',
          id: 'd52addef-6db6-470b-a62e-91bc473a8a19',
        });
        throw err;
      });
  }

  function searchContentHubExport(options: any): Promise<any> {
    exportingContentHub.value = true;

    let sortBy = getSortByForContentHubSearch(options);

    return client
      .value!.apiPost(
        `./ecom2/contenthub/search/export?$skip=${chProductSearchParams.value.Skip}&$top=${chProductSearchParams.value.Take}${sortBy}`,
        chProductSearchParams.value,
        null,
        { responseType: 'blob' },
      )
      .then(x => {
        FileSaver.saveAs(
          x.data,
          `content-hub-export-${dateFormat(new Date(), 'yyyymmdd-HHMMss')}.xlsx`,
        );
        exportingContentHub.value = false;
      })
      .catch(err => {
        exportingContentHub.value = false;
        store.commit('setError', {
          err: err,
          text: 'Error exporting Content Hub data',
          id: '9300149f-8e99-4d35-bb51-3c791e82c97a',
        });
        throw err;
      });
  }

  return {
    client,
    ignoredItems,
    ignoredItemsCount,
    odataProps,
    gettingIgnoredItems,
    gettingIgnoredItemsCount,
    updatingIgnoreItems,
    ignoreItemResults,
    progressId,
    progressDescription,
    exportingIgnoredItems,
    chProductSearchPayload,
    chProductSearchParams,
    chProductSearchItems,
    searchingContentHub,
    exportingContentHub,
    pcmProductStatusesToProduct,
    init,
    getIgnoredItems,
    getIgnoredItemsCount,
    deleteIgnoredItems,
    createIgnoredItem,
    createIgnoredItems,
    exportIgnoredItems,
    searchContentHub,
    searchContentHubExport,
  };
});
function getSortByForContentHubSearch(options: any) {
  let sortBy = '';
  if ((options.sortBy?.length ?? 0) === 1 && (options.sortDesc?.length ?? 0) === 1) {
    sortBy = `&$orderby=${options.sortBy[0]} ${options.sortDesc[0] ? 'desc' : 'asc'}`;
  }
  return sortBy;
}
